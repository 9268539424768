import cx from 'classnames'
import { ReactNode, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { useBeamSelector } from '../../../hooks'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamImageDataPointBlock } from '../../../stories/BeamImageDataPointBlock'
import { BeamLoadingIndicator } from '../../../stories/BeamLoadingIndicator'
import { BeamProgressBar } from '../../../stories/BeamProgressBar'
import { BeamSticker } from '../../../stories/BeamSticker'
import { BeamToast } from '../../../stories/BeamToast'
import { OverviewPartnerImpactResponse, TSite, TUser } from '../../../utils/types'
import { CustomError } from '../../../utils/types/CustomError'
import { CenteredContent } from '../../root/CenteredContent'
import { EnhancedFeaturesLockedButton } from '../common/EnhancedFeaturesLockedButton'
import { AutoBoostANonprofitModal } from '../NonprofitPartnersPage/AutoBoostANonprofitModal'
import { customErrorMessageHelper } from '../NonprofitPartnersPage/NonprofitPartnersPage'
import { RequestNewNonprofitCauseModal } from '../NonprofitPartnersPage/RequestNewNonprofitCauseModal'
import $$ from './overview-page.module.css'
import { progressBarData } from './OverviewPage.helper'

const FIRE_EMOJI = <>&#128293;</>

export const ActiveNonprofitPartnersBlock = ({
  impact,
  loading = true,
}: {
  impact: OverviewPartnerImpactResponse | null
  loading: boolean
}) => {
  const user: TUser | undefined = useBeamSelector(({ user }) => user)
  const site: TSite | undefined = useBeamSelector(({ site }) => site)
  const [modal, setModal] = useState(<></>)
  const [openToast, setOpenToast] = useState<boolean>(false)
  const [customErrorMessage, setCustomErrorMessage] = useState<CustomError | null>(null)
  const featureFlags = useFeatureFlags()

  const sortedNonprofits = useMemo(() => {
    if (!impact?.nonprofits) return []

    const listOfNonprofits = impact.nonprofits.filter(nonprofit => nonprofit.active)

    return listOfNonprofits
      .map(nonprofit => {
        const { completionRatio, timesCompleted, percentCompleted } = progressBarData(nonprofit)

        return {
          name: nonprofit.name,
          cause: nonprofit.cause,
          completionRatio,
          timesCompleted,
          percentCompleted,
          isTrending: nonprofit?.isTrending || null,
        }
      })
      .sort((a, b) => {
        return a.completionRatio === b.completionRatio
          ? 0
          : a.completionRatio > b.completionRatio
          ? -1
          : 1
      })
  }, [impact?.nonprofits])

  const EmptyState = ({ children }: { children: ReactNode }) => (
    <div className="h-96">
      <CenteredContent>{children}</CenteredContent>
    </div>
  )

  if (!user) return null
  if (!loading && !impact?.nonprofits) return <></>

  const handleOnClickBoost = () => {
    if (user.partnerId) {
      setModal(
        <AutoBoostANonprofitModal
          partnerId={user.partnerId}
          user={user}
          openToast={() => setOpenToast(true)}
          onCloseHandler={() => setModal(<></>)}
          setMessage={(e: CustomError | null) => {
            setCustomErrorMessage(e)
          }}
          openNonprofitModalAction={() => {
            if (user.partnerId) {
              setModal(
                <RequestNewNonprofitCauseModal
                  partnerId={user.partnerId}
                  openToast={() => setOpenToast(true)}
                  setMessage={(e: CustomError | null) => {
                    setCustomErrorMessage(e)
                  }}
                />
              )
            }
          }}
        />
      )
    }
  }

  return (
    <div className={cx($$.slot, 'grid grid-cols-1')}>
      <div className="col-span-2 ml-2">
        <h3 className={cx('beam--heading--3', $$.activeNonprofitPartnersHeader)}>
          Active Nonprofit Partners
        </h3>
        <span className={cx($$.managePartnersLink, 'mx-1 my-5 mt-5 mb-0 desktop:m-5 float-right')}>
          <Link to={`/nonprofits`}>Manage Partners</Link>
        </span>
      </div>

      <div className="grid pr-4 mb-4 space-x-4 space-y-4 overflow-y-scroll h-96">
        {loading && (
          <EmptyState>
            <BeamLoadingIndicator />
          </EmptyState>
        )}

        {!loading && impact && (
          <>
            {sortedNonprofits.length === 0 && (
              <div>
                You currently have no nonprofit partners. To add a partner(s) please click the{' '}
                <Link to={`/nonprofits`}>Manage Partners</Link> link or{' '}
                <Link to={`/contact-support`}>contact us</Link>.
              </div>
            )}
            <div className={'grid grid-cols-1 pl-4 mb-4'}>
              {sortedNonprofits.length > 0 &&
                sortedNonprofits.map((entry, i) => {
                  const { name, cause, timesCompleted, percentCompleted, isTrending } = entry

                  return (
                    <div key={i}>
                      <BeamProgressBar
                        key={i}
                        label={name}
                        value={Math.round(percentCompleted * 100)}
                        description={cause}
                        secondaryDescription={`Completed ${timesCompleted} times`}
                      />
                      {isTrending && (
                        <BeamSticker
                          label="Trending in the last 7 days"
                          icon={<span>{FIRE_EMOJI}</span>}
                        />
                      )}
                    </div>
                  )
                })}
            </div>
          </>
        )}
      </div>

      {user.partnerId && !site?.storeId && (
        <div className="col-span-2 mx-4">
          {!featureFlags['disable-enhanced-features'] ? (
            <EnhancedFeaturesLockedButton
              label={'Boost a Nonprofit'}
              onClick={handleOnClickBoost}
            />
          ) : (
            <BeamButton label={'Boost a Nonprofit'} onClick={handleOnClickBoost} />
          )}
        </div>
      )}

      {modal}

      <BeamToast
        onClose={() => setOpenToast(false)}
        open={openToast}
        closable={true}
        text={
          customErrorMessage
            ? customErrorMessageHelper(customErrorMessage)
            : 'Boost set up! Check your inbox for a confirmation email'
        }
        variant={customErrorMessage ? 'error' : 'success'}
        icon={customErrorMessage ? null : <span>&#127881;</span>}
        duration={10000}
      />
    </div>
  )
}

interface NonprofitSectionProps {
  impactData: Record<any, any> | null
  loading?: boolean
}

export const ImpactFromActiveNonprofits = ({
  impactData,
  loading = true,
}: NonprofitSectionProps) => {
  const EmptyState = ({ children }: { children: ReactNode }) => (
    <div className="h-96">
      <CenteredContent>{children}</CenteredContent>
    </div>
  )

  // Hide this block if there's no nonprofit data.
  if (!loading && !impactData) {
    return <></>
  }

  // Hide if partner did not achieve any goals yet.
  if (impactData?.filter((e: any) => e.cumulativeValue > 0).length === 0) {
    return <></>
  }

  return (
    <div className={cx($$.slot, 'grid grid-cols-1 mt-4', $$.impactFromActiveNonprofits)}>
      <div className="col-span-2 mb-4 ml-2">
        <h3 className={cx('beam--heading--3', $$.impactFromActiveNonprofitsHeading)}>
          Your Impact Milestones Achieved
        </h3>
        <p className="beam--paragraph--small">
          A new milestone will automatically appear for the first time when a community goal is
          achieved. After that, impact milestones will update in real-time to show the current
          impact funded as progress bars are filling up again
        </p>
      </div>

      {loading && (
        <EmptyState>
          <BeamLoadingIndicator />
        </EmptyState>
      )}

      <div className="grid grid-cols-2 overflow-y-scroll gap-x-2 gap-y-4 h-96">
        {!loading && impactData && (
          <>
            {impactData.map((e: any, i: number) => {
              if (+e.cumulativeValue > 0) {
                return (
                  <div key={i}>
                    <BeamImageDataPointBlock
                      datum={`${e.cumulativeValue} ${e.unit}`}
                      description={e.description}
                      icon={<img src={e.icon} />}
                      backgroundColor="--beam-color--coral-50"
                      className="h-48 mx-2"
                    />
                  </div>
                )
              }
            })}
          </>
        )}
      </div>
    </div>
  )
}

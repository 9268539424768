import { getV2ApiUrl } from '../../../api/auth'
import { axiosRequest } from '../../../utils/axiosRequest'

export async function fetchPresignedDownloadUrl(
  chainId: number,
  partnerUploadId: string
): Promise<{
  presignedDownloadUrl: string
}> {
  const url = `${getV2ApiUrl()}/partner/${chainId}/partnerUploads/${partnerUploadId}/requestDownload`
  const res = await axiosRequest('GET', url)

  return res?.data
}

import { useEffect, useState } from 'react'

import { BeamButton } from '../../../../stories/BeamButton'
import { BeamModal } from '../../../../stories/BeamModal'
import { useGtagWithContext } from '../../../root/BeamGoogleAnalytics/googleAnalyticsHelpers'
import image from './assets/firstTimeExperiencePreviewImage.png'
import $$ from './social-share-first-time-experience-modal.module.css'

export const SocialShareFirstTimeExperienceModal = ({
  onContinueHandler = () => {
    return
  },
}: {
  onContinueHandler?: () => void
}) => {
  const [open, setOpen] = useState(false)
  const gtag = useGtagWithContext()

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <BeamModal
      open={open}
      label={''}
      onCloseCallback={() => {
        gtag('first_time_promo_social_share_modal | close')
      }}
      body={
        <div className={$$.container}>
          <h3 className={$$.modalCallout}>NEW FEATURE</h3>
          <h1 className={$$.modalTitle} slot="label">
            🔓 Build Organic Buzz Around Your Campaign!
          </h1>
          <p className={$$.modalDescription}>
            Design and upload your own custom social share assets for each nonprofit you’re boosting
          </p>

          <h2 className={$$.subheading}>How Does It Work?</h2>
          <div className={$$.twoColumnContainer}>
            <div className={$$.leftColumn}>
              <img src={image} alt="Sample of Social Share Assets" />
            </div>
            <div className={$$.rightColumn}>
              <ul className={$$.list}>
                <li className={$$.listItem}>Schedule a Boosted Impact Campaign</li>
                <li className={$$.listItem}>
                  Upload a custom social share asset for each boosted nonprofit highlighting your
                  campaign
                </li>
                <li className={$$.listItem}>
                  Customers share their impact and build organic buzz around your campaign!
                </li>
              </ul>
            </div>
          </div>
        </div>
      }
      footer={
        <BeamButton
          label="Upload Campaign Social Share Assets"
          variant="basic"
          className={$$.button}
          fontWeight="normal"
          slot={'footer'}
          onClick={async () => {
            gtag('first_time_promo_social_share_modal | go_to_upload')
            onContinueHandler()
          }}
        />
      }
    />
  )
}

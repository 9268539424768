import { SlIcon } from '@shoelace-style/shoelace/dist/react'
import { ComponentProps, FC } from 'react'

import { BeamIconName } from '../../../stories/assets'

type SlIconProps = ComponentProps<typeof SlIcon>
interface BeamSlIconProps extends SlIconProps {
  name: BeamIconName
}

export const BeamSlIcon: FC<BeamSlIconProps> = props => {
  return (
    <SlIcon
      {...props}
      library={'system'}
      style={{ width: '100%', height: '100%', ...props.style }}
    />
  )
}

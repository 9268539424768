import cx from 'classnames'
import { createContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useBeamSelector } from '../../../../hooks'
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags'
import { BeamButton } from '../../../../stories/BeamButton'
import { BeamCTA } from '../../../../stories/BeamCTA'
import { BeamDropdownOnChangeEvent } from '../../../../stories/BeamDropdown/BeamDropdown.types'
import { BeamToast } from '../../../../stories/BeamToast'
import { TSite, TUser } from '../../../../utils/types'
import { BeamSEO } from '../../../root/BeamSEO'
import { EnhancedFeaturesLockedButton } from '../../common/EnhancedFeaturesLockedButton'
import { PromoModalsComponent } from '../PromoModalsComponent/PromoModalsComponent'
import { Megaphone } from './assets/Megaphone'
import $$ from './campaign-page.module.css'
import { CampaignDropdown } from './CampaignDropdown'
import { fetchCampaignData } from './CampaignPage.api'
import { CompletedPromosTable } from './CompletedPromosTable'
import { marketingVaultUrl } from './constants'
import { APIPromoObject, GetAllPromosResponse, PromoModal } from './promo.types'
import { UpcomingAndLivePromosTable } from './UpcomingAndLivePromosTable'

interface ModalContextProps {
  modal: PromoModal | null
  setModal: (modal: PromoModal) => void
  currentPromo: APIPromoObject | null
  setCurrentPromo: (promo: APIPromoObject | null) => void
}

export const GWB_LOCKED_CTA_COPY =
  '0 campaigns remaining. Contact Client Strategy to upgrade to Grow with Beam today and get access unlimited campaigns'

export const ModalContext = createContext<ModalContextProps>({
  modal: PromoModal.NoModal,
  setModal: () => null,
  currentPromo: null,
  setCurrentPromo: () => null,
})

export const CampaignsPage = () => {
  const user: TUser | undefined = useBeamSelector(({ user }) => user)
  const [campaignData, setCampaignData] = useState<GetAllPromosResponse | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [fetchError, setFetchError] = useState<string | null>(null)
  const [modal, setModal] = useState(PromoModal.NoModal)
  const [currentPromo, setCurrentPromo] = useState<APIPromoObject | null>(null)
  const siteFilter: TSite | undefined = useBeamSelector(({ site }) => site)
  const featureFlags = useFeatureFlags()
  const history = useHistory()
  const canAccessCampaignActions = user?.enhancedFeatures.permissions.campaign_action.access
  const canAccessSwapActions = false //TODO: https://beamimpact.atlassian.net/browse/SDK-2566
  const canAccessInfluencerActions = true //TODO: https://beamimpact.atlassian.net/browse/SDK-2566
  const isCampaignButtonLocked = !(
    canAccessCampaignActions ||
    canAccessSwapActions ||
    canAccessInfluencerActions
  )

  const dropdownOptions = [
    { label: 'Boost Campaign', value: 'boost', disabled: !canAccessCampaignActions },
    {
      label: 'Swap Campaign',
      value: 'swap',
      disabled: !canAccessSwapActions,
      disabledCopy: 'This feature is available for Grow with Beam members', //TODO: https://beamimpact.atlassian.net/browse/SDK-2566
    },
    {
      label: 'Influencer Campaign',
      value: 'influencer',
      disabled: !canAccessInfluencerActions,
      disabledCopy: 'This feature is available for Grow with Beam members', //TODO: https://beamimpact.atlassian.net/browse/SDK-2566
    },
  ]

  const onClickCreateCampaign = (e: BeamDropdownOnChangeEvent) => {
    const selectedValue = e.target.value

    switch (selectedValue) {
      case 'swap':
        console.log('Swap Campaign selected')
        break
      case 'boost':
        onClickStartCampaign()
        break
      case 'influencer':
        history.push('/nonprofits/campaigns/influencer/new')
        break
      default:
        return
    }
  }

  const loadListOfCampaigns = async () => {
    setFetchError(null)
    if (!user?.chainId) {
      return
    }

    try {
      setLoading(true)
      const data = await fetchCampaignData(user.chainId)
      setCampaignData(data)
      setLoading(false)
    } catch (error: any) {
      setFetchError(error.message)
    }
  }

  useEffect(() => {
    if (!user?.chainId) return
    loadListOfCampaigns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.chainId])

  // Make sure the campaign dashboard is updated with the latest list of promos
  // each time the user make edits to a promo.
  useEffect(() => {
    if (!user?.chainId || modal !== PromoModal.NoModal) return
    loadListOfCampaigns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal])

  const openMarketingVaultLink = () => {
    window.open(marketingVaultUrl, '_blank')
  }

  if (!user) {
    return null
  }

  const onClickStartCampaign = () => {
    setCurrentPromo(null)
    setModal(PromoModal.BoostANonprofit)
  }

  return (
    <ModalContext.Provider
      value={{
        modal,
        setModal,
        currentPromo,
        setCurrentPromo,
      }}>
      <BeamSEO title={`Boosted Campaigns`} />
      <main className={'beam--page-content--no-gutter'}>
        <section className={'relative mx-5 my-5'}>
          <h1>Campaign Management {!!siteFilter?.storeId && <>(All Sites)</>}</h1>

          {!!siteFilter?.storeId && (
            <p className={$$.campaignParagraph}>
              Campaigns listed here are not specific to the site selected.
            </p>
          )}

          <BeamCTA
            className="!mb-6"
            backgroundColor="--beam-color--sky-50"
            leftContent={
              <div>
                <h3 className="m-0 text-sky-600">
                  <span className="inline-block mr-2 align-middle">
                    <Megaphone />
                  </span>
                  <span className="inline-block align-middle">
                    Supercharge Your Boosted Impact Campaigns
                  </span>
                </h3>
                <p className="beam--paragraph--small text-sky-600">
                  Make your campaigns more effective and go to our Marketing Vault for assets you
                  can use to market Beam to your customers and examples of marketing you can design
                  to boost awareness of your campaigns
                </p>
              </div>
            }
            rightContent={
              <BeamButton
                label={'Go to Marketing Vault'}
                variant="basic_blue"
                onClick={openMarketingVaultLink}
              />
            }
          />

          {campaignData &&
            campaignData.currentPromosCount + campaignData.completedPromosCount === 0 && (
              <>
                <h2 className="col-span-8">Current Campaigns</h2>
                <BeamCTA
                  leftContent={
                    <>
                      <h3 className="my-1">Set Up Your First Boosted Impact Campaign</h3>
                      <p
                        className="beam--paragraph--small"
                        style={{ color: 'var(--beam-color--sky-800)' }}>
                        You have no Boosted Impact Campaigns scheduled yet!
                      </p>
                    </>
                  }
                  rightContent={
                    <>
                      {!featureFlags['disable-enhanced-features'] &&
                      !featureFlags['influencer-campaign'] ? (
                        <EnhancedFeaturesLockedButton
                          label={'Set Up First Boost'}
                          onClick={() => {
                            setCurrentPromo(null)
                            setModal(PromoModal.BoostANonprofit)
                          }}
                          variant={'emphasis_white'}
                          block={true}
                          size="small"
                        />
                      ) : featureFlags['influencer-campaign'] &&
                        !featureFlags['disable-enhanced-features'] ? (
                        <CampaignDropdown
                          onChange={onClickCreateCampaign}
                          disabled={isCampaignButtonLocked}
                          disabledCopy={GWB_LOCKED_CTA_COPY}
                          dropdownOptions={dropdownOptions}
                        />
                      ) : (
                        <BeamButton
                          label={'Set Up First Boost'}
                          onClick={() => {
                            setCurrentPromo(null)
                            setModal(PromoModal.BoostANonprofit)
                          }}
                          variant={'emphasis_white'}
                          block={true}
                          size="small"
                        />
                      )}
                    </>
                  }
                  backgroundColor="--beam-color--sky-50"
                  className={cx($$.textColor, 'pb-2 pt-1')}
                />
              </>
            )}

          {campaignData && campaignData.currentPromosCount > 0 && (
            <>
              <section>
                <div className="grid grid-cols-12">
                  <h2 className="col-span-10">Current and Upcoming Campaigns</h2>

                  {!siteFilter?.storeId && (
                    <>
                      {!featureFlags['disable-enhanced-features'] &&
                      !featureFlags['influencer-campaign'] ? (
                        <EnhancedFeaturesLockedButton
                          label="Start A Campaign"
                          className="col-span-2"
                          onClick={onClickStartCampaign}
                        />
                      ) : featureFlags['influencer-campaign'] &&
                        !featureFlags['disable-enhanced-features'] ? (
                        <CampaignDropdown
                          onChange={onClickCreateCampaign}
                          disabled={isCampaignButtonLocked}
                          disabledCopy={GWB_LOCKED_CTA_COPY}
                          dropdownOptions={dropdownOptions}
                        />
                      ) : (
                        <BeamButton
                          label="Set Up New Boost"
                          variant="elevated"
                          className="col-span-2"
                          onClick={onClickStartCampaign}
                        />
                      )}
                    </>
                  )}
                </div>
              </section>

              <UpcomingAndLivePromosTable
                data={campaignData?.currentPromos}
                loading={loading}
                user={user}
              />
            </>
          )}
          {campaignData && campaignData.completedPromosCount > 0 && (
            <>
              <section>
                <div className="grid grid-cols-12">
                  <h2 className="col-span-10 mt-6">Completed Campaigns</h2>
                  {!siteFilter?.storeId && campaignData.currentPromosCount === 0 && (
                    <>
                      {!featureFlags['disable-enhanced-features'] &&
                      !featureFlags['influencer-campaign'] ? (
                        <EnhancedFeaturesLockedButton
                          label="Start A Campaign"
                          className="col-span-2"
                          onClick={onClickStartCampaign}
                        />
                      ) : featureFlags['influencer-campaign'] &&
                        !featureFlags['disable-enhanced-features'] ? (
                        <CampaignDropdown
                          onChange={onClickCreateCampaign}
                          disabled={isCampaignButtonLocked}
                          disabledCopy={GWB_LOCKED_CTA_COPY}
                          dropdownOptions={dropdownOptions}
                        />
                      ) : (
                        <BeamButton
                          label="Start A Campaign"
                          variant="elevated"
                          className="col-span-2"
                          onClick={() => {
                            setCurrentPromo(null)
                            setModal(PromoModal.BoostANonprofit)
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              </section>
              <CompletedPromosTable data={campaignData?.completedPromos} loading={loading} />
            </>
          )}
        </section>

        {/* Error Toast */}
        <BeamToast
          open={!!fetchError}
          text={fetchError || ''}
          variant={'error'}
          onClose={() => setFetchError(null)}
        />
      </main>

      <PromoModalsComponent activeModal={modal} currentPromo={currentPromo} />
    </ModalContext.Provider>
  )
}

import { AxiosResponse, Method } from 'axios'
import { isNil } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

import { axiosRequest } from '../utils/axiosRequest'

interface BeamFetchOptions extends RequestInit {
  maxRetryCount?: boolean
}
/**
 * Generic data fetching hook that implicitly fetches the data from the given url.
 */
export function useBeamFetch<Res = unknown>(url: string, options: BeamFetchOptions) {
  const [data, setData] = useState<Res | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [attemptCount, setAttemptCount] = useState<number>(0)

  const { maxRetryCount = 2 } = options

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    setIsFetching(true)
    setError(null)
    const updatedAttemptCount = attemptCount + 1

    try {
      const response = (await axiosRequest(
        options?.method as Method,
        url,
        options.body
      )) as AxiosResponse<Res>
      setData(response.data)
    } catch (err) {
      console.error(err)
      if (updatedAttemptCount >= maxRetryCount) {
        setError(err instanceof Error ? err.message : 'An unknown error occurred.')
      }
    } finally {
      setAttemptCount(updatedAttemptCount)
      setIsLoading(false)
      setIsFetching(false)
    }
  }, [attemptCount, options?.method, options.body, url, maxRetryCount])

  useEffect(
    function fetchDataOnLoad() {
      if (!isFetching && attemptCount < maxRetryCount && isNil(data)) {
        fetchData()
      }
    },
    [attemptCount, data, fetchData, isFetching, maxRetryCount]
  )

  const refetch = useCallback(() => {
    setAttemptCount(0)
    fetchData()
  }, [fetchData])

  return { data, isLoading, error, refetch }
}

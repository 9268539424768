let baseUrl = ''

// TODO: Use env variables once we flesh out our deployment flow.
if (window.location.href.includes('localhost')) {
  baseUrl = 'http://localhost:4000/api'
} else if (window.location.href.includes('dev')) {
  baseUrl = 'https://development-partner-portal-api.beamimpact.com/api'
} else if (window.location.href.includes('vercel')) {
  baseUrl = 'https://development-partner-portal-api.beamimpact.com/api'
} else if (window.location.href.includes('staging')) {
  baseUrl = 'https://staging-partner-portal-api.beamimpact.com/api'
} else {
  baseUrl = 'https://production-partner-portal-api.beamimpact.com/api'
}

export const API_BASE_PATH_PORTAL = baseUrl

/**
 * Returns Beam's Partner Portal v2 API url.
 * Optionally accepts a path that, when given, returns the full endpoint url, removing the need for interpolation.
 * @param path - Path with leading slash. When given, returns full url instead of base url
 */
export function getV2ApiUrl(path?: string): string {
  let baseUrl = ''
  const href = window.location.href

  if (href.includes('localhost')) {
    baseUrl = 'http://localhost:4000/api/v2'
  } else if (href.includes('dev-partner-portal') || href.includes('vercel')) {
    baseUrl = 'https://development-partner-portal-api.beamimpact.com/api/v2'
  } else if (href.includes('staging-partner-portal')) {
    baseUrl = 'https://staging-partner-portal-api.beamimpact.com/api/v2'
  } else {
    baseUrl = 'https://production-partner-portal-api.beamimpact.com/api/v2'
  }

  if (path) {
    return baseUrl + path
  }

  return baseUrl
}

/**
 * Checks if the client is in a dev environment based on the url. localhost and dev are considered dev environments.
 */
export const inDevEnvironment =
  window?.location?.href?.includes('localhost') || window?.location?.href?.includes('dev')

export const inStagingEnvironment = window?.location?.href?.includes('staging')

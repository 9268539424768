import { ReactNode } from 'react'

import { ReactComponent as InfoIcon } from '../assets/infoIcon.svg'
import { BeamCard } from '../BeamCard'

interface BeamInfoPanelProps {
  children: ReactNode
}
export const BeamInfoPanel = ({ children }: BeamInfoPanelProps) => {
  return (
    <BeamCard backgroundColor="--beam-color--sky-50" className="mb-8" excludeBorder>
      <div className="flex flex-row">
        <div className="flex items-center mr-3">
          <InfoIcon data-testid="beamInfoIcon" />
        </div>
        <div className="font-secondary text-[15px] font-[400] leading-5 text-sky-800">
          {children}
        </div>
      </div>
    </BeamCard>
  )
}

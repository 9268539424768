import {
  SET_ACCESS_TOKEN,
  SET_DISPLAY_LIST_OF_SITES,
  SET_INVOICES,
  SET_LOADING_INVOICES,
  SET_LOADING_SITES,
  SET_LOADING_SUPPORT_EMAIL,
  SET_LOADING_USER,
  SET_SITE,
  SET_SITES,
  SET_USER,
} from './constants'

export const setAccessToken = value => {
  return {
    type: SET_ACCESS_TOKEN,
    value,
  }
}

export const setLoadingUser = value => {
  return {
    type: SET_LOADING_USER,
    value,
  }
}

export const setUser = user => {
  return {
    type: SET_USER,
    user,
  }
}

export const setLoadingSites = value => {
  return {
    type: SET_LOADING_SITES,
    value,
  }
}

export const setSite = site => {
  return {
    type: SET_SITE,
    site,
  }
}

export const setSites = sites => {
  return {
    type: SET_SITES,
    sites,
  }
}

export const setDisplayListOfSites = value => {
  return {
    type: SET_DISPLAY_LIST_OF_SITES,
    value,
  }
}

export const setInvoices = invoices => {
  return {
    type: SET_INVOICES,
    invoices,
  }
}

export const setLoadingInvoices = value => {
  return {
    type: SET_LOADING_INVOICES,
    value,
  }
}

export const setLoadingSupportEmail = value => {
  return {
    type: SET_LOADING_SUPPORT_EMAIL,
    value,
  }
}

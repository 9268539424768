import { format } from 'date-fns'
import { format as dateFnsFormat, utcToZonedTime } from 'date-fns-tz'

export function formatDate(date: string, pattern: string) {
  const formattedDate = new Date(date)
  if (isNaN(formattedDate.getTime())) {
    return date
  }

  formattedDate.setDate(formattedDate.getDate())
  return format(formattedDate, pattern)
}

export function formatDateTz(date: string, pattern = "LLL d, yyyy 'at' hh:mm aaa z") {
  const formattedDate = new Date(date)
  if (isNaN(formattedDate.getTime())) {
    return date
  }

  formattedDate.setDate(formattedDate.getDate())
  return dateFnsFormat(formattedDate, pattern)
}

/**
 * Returns a valid, localized datetime format for an input type 'datetime-local'
 * @example
 * const utcDate = '2024-11-16T03:59:00.000Z'
 * const localDate = toInputDatetimeFormat(localDate)
 *
 * <input type="datetime-local" value={localDate} />
 */
export function toInputDatetimeFormat(utcDate: string | Date) {
  const newDate = new Date(utcDate) // this would be your selected date
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const localDate = utcToZonedTime(newDate, timeZone)
  const formattedDate = dateFnsFormat(localDate, "yyyy-MM-dd'T'HH:mm")
  return formattedDate
}

import cx from 'classnames'

import { Cause1 } from './assets/Cause1'
import { Cause2 } from './assets/Cause2'
import { Cause3 } from './assets/Cause3'
import { Cause4 } from './assets/Cause4'
import { Notification } from './assets/Notification'
import { ProgressBar } from './assets/ProgressBar'
import $$ from './select-nonprofit-widget-preview.module.css'

interface SelectNonprofitWidgetPreviewProps {
  campaignName: string
  campaignNameCharacterLimit?: number
  /**
   * Optional promo pill text. A default fallback message is displayed when excluded.
   */
  promoText?: string
  colorPrimary?: string
  foregroundColor?: string
  multiplier: number
}

export const SelectNonprofitWidgetPreview = ({
  campaignName,
  campaignNameCharacterLimit = 23,
  promoText,
  colorPrimary,
  foregroundColor,
  multiplier,
}: SelectNonprofitWidgetPreviewProps) => {
  const promoPillText = promoText
    ? promoText
    : `Donations are ${multiplier ? multiplier : '_'}x for ${
        campaignName ? campaignName.slice(0, campaignNameCharacterLimit) : '＿'.repeat(10)
      }`
  const campaignColor = colorPrimary ? colorPrimary : 'var(--beam-color--charcoal-600)'
  const campaignTextColor = foregroundColor ? foregroundColor : 'var(--beam-color--white)'
  return (
    <div className="select-none">
      <div>
        <span
          className={cx($$.promoPillbox, 'inline-flex overflow-hidden')}
          style={{ backgroundColor: campaignColor, color: campaignTextColor }}>
          {promoPillText}
        </span>
        <span className={cx($$.widgetTitle, 'float-left')}>Choose your impact</span>
      </div>
      <div className={cx($$.widgetDescription, 'float-left')}>
        <b>At no extra cost,</b> select a nonprofit and 1% of your purchase will be donated for you.
      </div>
      <div className={cx($$.nonprofitButtonContainer, 'flex')}>
        <div className="bg-sky-50">
          <Cause1 />
          <Notification style={{ fill: campaignColor }} className={$$.nonprofitNotification} />
        </div>
        <div className="bg-charcoal-50">
          <Cause2 />
        </div>
        <div className="bg-charcoal-50">
          <Cause3 />
        </div>
        <div className="bg-charcoal-50">
          <Cause4 />
        </div>
      </div>
      <div className={$$.impactPreview}>
        <div className={cx($$.impactPreviewHeader, 'flex justify-between')}>
          <span className="font-bold">
            {multiplier ? `${multiplier}X BOOSTING DONATIONS` : 'FOOD INSECURITY'}
          </span>
          <span>POWERED BY BEAM</span>
        </div>
        <div className={$$.impactPreviewBody}>
          Fund 20 meals for food insecure families in need via LA Food Bank
        </div>
        <div className={cx($$.impactProgressBar, 'flex items-center')}>
          <ProgressBar />
          <span className="flex-shrink-0 pl-2 text-xs">54% funded</span>
        </div>
      </div>
    </div>
  )
}

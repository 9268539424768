import { isNil } from 'lodash'

import { EUserType, TUser } from '../types'

export const partnerPortalUserTypes: EUserType[] = [
  EUserType.Engineering,
  EUserType.Executive,
  EUserType.Finance,
  EUserType.Marketing,
  EUserType.Staff,
]

export function isAuthenticated(user: TUser | undefined) {
  return user && !isNil(user.type) && !isNil(user.id) && partnerPortalUserTypes.includes(user.type)
}

import cx from 'classnames'
import { useEffect, useState } from 'react'

import $$ from './beam-progress-bar-v2.module.css'

interface ProgressBarProps {
  /**
   * The bar's current progress. Set it to 0 if using fake progress.
   */
  progress: number
  disableAnimation?: boolean
  showPercentage?: boolean
  fakeProgressProps?: {
    usesFakeProgress?: boolean
    fakeProgressComplete?: boolean
  }
}

export const BeamProgressBarV2 = ({
  progress,
  disableAnimation = false,
  showPercentage = false,
  fakeProgressProps = {
    usesFakeProgress: false,
    fakeProgressComplete: false,
  },
}: ProgressBarProps) => {
  const [fakeProgress, setFakeProgress] = useState(0)

  useEffect(
    function updateFakeProgress() {
      let interval: NodeJS.Timeout | null = null

      if (fakeProgressProps?.usesFakeProgress) {
        if (fakeProgressProps?.fakeProgressComplete) {
          setFakeProgress(100)
        } else if (fakeProgress < 90) {
          interval = setInterval(() => {
            setFakeProgress(prev => Math.min(prev + 10, 90))
          }, 100)
        }
      } else {
        // Reset fake progress if not in use
        setFakeProgress(0)
      }

      return function cleanup() {
        if (interval) {
          clearInterval(interval)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fakeProgressProps?.usesFakeProgress, fakeProgressProps?.fakeProgressComplete]
  )

  return (
    <div className={$$.progressContainer}>
      <div className={$$.progressBase}>
        <div
          className={cx($$.progressBar, {
            [$$.noAnimation]: disableAnimation,
          })}
          style={{ width: `${fakeProgressProps?.usesFakeProgress ? fakeProgress : progress}%` }}
        />
      </div>
      {showPercentage && (
        <span className={$$.percentageLabel}>
          {fakeProgressProps?.usesFakeProgress ? fakeProgress : progress}%
        </span>
      )}
    </div>
  )
}

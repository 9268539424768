import { SlIcon } from '@shoelace-style/shoelace/dist/react'

import { BeamButton } from '../../../../../../stories/BeamButton'
import $$ from '../influencer-campaign-form.module.css'

interface InfluenceLinkUploadButtonProps {
  disabled?: boolean
  onClick: () => void
}

export const InfluenceLinkUploadButton = ({
  disabled = false,
  onClick,
}: InfluenceLinkUploadButtonProps) => {
  return (
    <div>
      <label className={$$.formInputLabel} htmlFor={'displayInfluencerNameToggle'}>
        Influencer Link Upload
      </label>
      <p className={'field-label-subtext mb-2'}>
        Upload a CSV file with three column headers: influencer handle, custom link, unique code
      </p>
      <div className={'tablet:max-w-[250px] desktop:max-w-[450px]'}>
        <BeamButton variant={'white'} disabled={disabled} type={'button'} onClick={onClick}>
          Start Upload
          <SlIcon
            slot={'prefix'}
            library={'system'}
            name={'uploadIcon'}
            className={'text-sky-800'}
            style={{ width: '100%' }}
          />
        </BeamButton>
      </div>
    </div>
  )
}

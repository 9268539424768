import { delay } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

import { BeamButton } from '../../../../../../stories/BeamButton'
import {
  FileStatusLabel,
  FileWithDisplayName,
} from '../../../../../../stories/BeamDropzone/beam-file-dropzone-types'
import { BeamFileDropzone } from '../../../../../../stories/BeamDropzone/BeamFileDropzone'
import { convertToFileWithDisplayName } from '../../../../../../stories/BeamDropzone/beamFileDropzoneHelpers'
import { BeamExpandableDetails } from '../../../../../../stories/BeamExpandableDetails'
import { BeamModal } from '../../../../../../stories/BeamModal'
import { useGtagWithContext } from '../../../../../root/BeamGoogleAnalytics/googleAnalyticsHelpers'
import { marketingVaultUrl } from '../../constants'
import { validateAffiliateCodeCsv } from '../api'
import { convertCsvFileToString, countPromoCodes } from '../helpers'
import { InfluencerFormData } from '../new/data-store'

interface CsvUploadModalProps {
  isOpen: boolean
  handleClose: () => void
  onCsvUploadChange: (fileUpload: FileWithDisplayName | null) => void
  formData: InfluencerFormData
}

export const CsvUploadModal = ({
  isOpen,
  handleClose,
  onCsvUploadChange,
  formData,
}: CsvUploadModalProps) => {
  const [fileUpload, setFileUpload] = useState<FileWithDisplayName | null>(null)
  const [promoCodesCount, setPromoCodesCount] = useState<number>(0)
  const gtag = useGtagWithContext()

  const handleFileUploadCallback = useCallback(
    async (file: File, updateFileStatus: (status: FileStatusLabel, errors?: string[]) => void) => {
      updateFileStatus('loading')

      try {
        const response = await validateAffiliateCodeCsv({
          value: await convertCsvFileToString(file),
          dateRange: {
            startsAt: new Date(formData.boostStartDate).toISOString(),
            endsAt: new Date(formData.boostEndDate).toISOString(),
          },
          schema: {
            name: 'influencer',
            version: '1.0.0',
          },
        })

        const promoCodesCount = countPromoCodes(response.value.promoCodeGroups)
        setPromoCodesCount(promoCodesCount)

        // delay to make the loading bar experience smoother. Otherwise, it pops in and out in an instant.
        delay(() => {
          if (response.errors?.length) {
            updateFileStatus(
              'error',
              response.errors.map(e => e.message)
            )
          } else {
            updateFileStatus('completed')
            onCsvUploadChange(convertToFileWithDisplayName(file, promoCodesCount))
          }
        }, 2000)
      } catch (err) {
        console.error('Error uploading file:', err)
        updateFileStatus('error', ['This was an error verifying your file.'])
      }
    },
    [formData.boostEndDate, formData.boostStartDate, onCsvUploadChange]
  )

  const handleFileRemoveCallback = useCallback(() => {
    onCsvUploadChange(null)
  }, [onCsvUploadChange])

  useEffect(
    function updateFileDisplayName() {
      if (fileUpload?.file) {
        setFileUpload(convertToFileWithDisplayName(fileUpload.file, promoCodesCount))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fileUpload?.file.name, fileUpload?.displayName, promoCodesCount]
  )

  return (
    <BeamModal
      label={'Tracking Link/Code Upload'}
      disableClickOutside={true}
      subheading={
        <div>
          Here is some info on influencer upload lorem ipsum. Here is a example link of an{' '}
          <a href={'#TODO'} target={'_blank'} rel={'noopener noreferrer'}>
            Influencer csv
          </a>
        </div>
      }
      open={isOpen}
      onCloseCallback={handleClose}
      body={
        <form className={'pb-20'}>
          <BeamExpandableDetails label="CSV Upload Guidelines" className={'mt-6'}>
            <div>These are the requirements for the csv file:</div>
            <ul className={'list-disc list-inside'}>
              <li>Must be a .csv file</li>
              <li>Must be no larger than 6MB</li>
            </ul>
            <br />
            <div className={'mb-3'}>Want to see some examples of influencer marketing?</div>
            <BeamButton
              label="Check out our Marketing Vault"
              variant="basic_blue"
              className={'w-min'}
              onClick={() => {
                gtag('promo_influencer_csv_upload_modal | template_drive')
                window.open(marketingVaultUrl, '_blank', `rel="noopener noreferrer`)
              }}
            />
          </BeamExpandableDetails>

          <div className={'py-6'}>
            <BeamFileDropzone
              accept={['.csv']}
              inputProps={{
                name: 'influencerCsv',
              }}
              handleFileUploadCallback={handleFileUploadCallback}
              onFileRemoveCallback={handleFileRemoveCallback}
              fileUploadsState={{
                fileUploads: fileUpload ? [fileUpload] : [],
                setFileUploads: fileUploads => {
                  setFileUpload(fileUploads[0])
                },
              }}
            />
          </div>
        </form>
      }
      footer={
        <div slot={'footer'}>
          <BeamButton label={'Confirm'} disabled={!fileUpload} onClick={handleClose} />
        </div>
      }
    />
  )
}

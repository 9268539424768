import { SlCopyButton } from '@shoelace-style/shoelace/dist/react'

interface DevConsoleCopyButtonProps {
  copyLabel?: string
  value: string
}

export const DevConsoleCopyButton = ({ copyLabel, value }: DevConsoleCopyButtonProps) => {
  return (
    <SlCopyButton
      className={'text-charcoal-400 hover:text-sky-600'}
      copyLabel={copyLabel || 'Copy to clipboard'}
      value={value}
      hoist
    />
  )
}

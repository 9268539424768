import { SlIconButton } from '@shoelace-style/shoelace/dist/react'
import cx from 'classnames'
import { ComponentProps, FC } from 'react'

import { BeamIconName } from '../../../../stories/assets'
import $$ from './beam-sl-icon-button.module.css'

type SlIconProps = ComponentProps<typeof SlIconButton>
interface BeamSlIconProps extends SlIconProps {
  name: BeamIconName
}

/**
 * SlIcon button uses fontSize to control its size.
 */
export const BeamSlIconButton: FC<BeamSlIconProps> = props => {
  return (
    <SlIconButton
      {...props}
      className={cx($$.beamSlIconButton, props.className)}
      library={'system'}
    />
  )
}

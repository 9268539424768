import { useReducer } from 'react'

import { FileWithDisplayName } from '../../../../../../stories/BeamDropzone/beam-file-dropzone-types'
import { formatDateTimeForDateInput } from '../../../../../../utils/formatDateTimeForDateInput'
import { APIPromoCodeGroup, GetPromoByIdResponse } from '../../promo.types'

type FormStatus = 'idle' | 'submitting' | 'success'

export interface InfluencerFormData {
  campaignName: string
  nonprofitIds: string[]
  boostMultiplier: number
  boostStartDate: string
  boostEndDate: string
  displayAffiliateName: boolean
  campaignPillColor: string
  campaignTextColor: string
  promoCodeGroups: APIPromoCodeGroup[] | null
  fileUpload: FileWithDisplayName | null
}

interface NewInfluencerCampaignState {
  formData: InfluencerFormData
  formStatus: FormStatus
  formError: string | null
}

type NewInfluencerCampaignAction =
  | {
      type: 'set_form_field'
      name: keyof InfluencerFormData
      value: any
    }
  | {
      type: 'bulk_set_form_field'
      fields: Partial<InfluencerFormData>
    }
  | {
      type: 'set_form_status'
      value: FormStatus
    }
  | {
      type: 'set_form_error'
      error: string | null
    }
  | {
      type: 'set_csv_upload_value'
      value: FileWithDisplayName | null // CSV in string form
    }

const initialState: NewInfluencerCampaignState = {
  formData: {
    campaignName: '',
    nonprofitIds: [],
    boostMultiplier: 1,
    boostStartDate: '',
    boostEndDate: '',
    displayAffiliateName: true,
    campaignPillColor: '',
    campaignTextColor: '',
    promoCodeGroups: null,
    fileUpload: null,
  },
  formStatus: 'idle',
  formError: null,
}

export function newInfluencerCampaignReducer(
  state: NewInfluencerCampaignState,
  action: NewInfluencerCampaignAction
): NewInfluencerCampaignState {
  switch (action.type) {
    case 'set_form_field': {
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.name]: action.value,
        },
      }
    }
    case 'bulk_set_form_field': {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.fields,
        },
      }
    }
    case 'set_form_status': {
      return {
        ...state,
        formStatus: action.value,
      }
    }
    case 'set_form_error': {
      return {
        ...state,
        formError: action.error,
      }
    }
    case 'set_csv_upload_value': {
      return {
        ...state,
        formData: {
          ...state.formData,
          fileUpload: action.value,
        },
      }
    }
  }
}

export function useNewInfluencerCampaignReducer() {
  return useReducer(newInfluencerCampaignReducer, initialState)
}

export function mapPromoDataToInfluencerFormData(
  promoData: GetPromoByIdResponse
): InfluencerFormData {
  return {
    boostEndDate: promoData.endTime ? formatDateTimeForDateInput(promoData.endTime) : '',
    boostStartDate: formatDateTimeForDateInput(promoData.startTime),
    boostMultiplier: promoData.multiplier,
    campaignName: promoData.name,
    campaignTextColor: promoData.foregroundColor || '',
    campaignPillColor: promoData.colorPrimary || '',
    nonprofitIds: promoData.activeNonprofits.map(String),
    displayAffiliateName: promoData.displayAffiliateName || false,
    promoCodeGroups: promoData.promoCodeGroups || [],
    fileUpload: null,
  }
}

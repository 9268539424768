import { useEffect, useMemo, useState } from 'react'

import { API_BASE_PATH_PORTAL } from '../../../../../api/auth'
import { useBeamSelector } from '../../../../../hooks'
import { axiosRequest } from '../../../../../utils/axiosRequest'
import {
  OverviewNonprofitImpactObject,
  OverviewPartnerImpactResponse,
  TUser,
} from '../../../../../utils/types'
import { getActiveNonprofitDropdownOptions } from '../../AutoBoostANonprofitModal'

export function useFetchChainNonprofitOptions() {
  const user: TUser = useBeamSelector(({ user }) => user)
  const [nonprofits, setNonprofits] = useState<OverviewNonprofitImpactObject[]>([])

  useEffect(function fetchChainNonprofits() {
    ;(async () => {
      try {
        // TODO: Create a new endpoint for just fetching active nonprofit IDs without all the extra data
        const res = await axiosRequest(
          'get',
          `${API_BASE_PATH_PORTAL}/partners/impact/${user.partnerId}`
        )
        const impactData = res?.data as OverviewPartnerImpactResponse
        setNonprofits(impactData?.nonprofits || [])
      } catch (error: any) {
        console.error(error)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const nonprofitOptions = useMemo(() => {
    return getActiveNonprofitDropdownOptions(nonprofits)
  }, [nonprofits])

  return { nonprofitOptions }
}

export type Maybe<T> = T | null | undefined

// From API schema
export enum EUserType {
  Admin = 'Admin',
  Engineering = 'Engineering',
  Super = 'Super',
  Executive = 'Executive',
  Marketing = 'Marketing',
  Finance = 'Finance',
  Staff = 'Staff',
}

export interface UserFeatureFlag {
  key: string
  active: boolean
}

export interface UpcomingPromosInfo {
  campaignName: string | undefined
  startTime: Date
  daysUntilLive: number
}

export enum AccountType {
  internal = 'internal',
  external = 'external',
}

export interface EnhancedFeaturePermissionsObject {
  permissions: {
    campaign_action: {
      access: boolean
      countUsed: number
      countRemaining: number | 'Unlimited'
      maximum: number | 'Unlimited'
    }
    social_share_upload: {
      access: boolean
      countUsed: number
      countRemaining: number | 'Unlimited'
      maximum: number | 'Unlimited'
    }
  }
}

// Partner Portal session user
export interface TUser {
  autopayModalSeenCount: number
  canSeeCumulativeImpactCTA: boolean
  chainId: number
  cumulativeImpactRequest: boolean
  email: string
  firstName?: string
  id: string
  lastName?: string
  partner: {
    name: string
    usePpgf: boolean
    ppgfStatus: string
    launchDate: Date | string | null
    logo?: string
    canSeeAutopayFeatures: boolean
    invoiceEmail: string
    invoiceCcEmails: string[]
    offersSubscriptionProduct: boolean
    inKindDonations: { displayInKindDonations: boolean; donationName: string }
    redemptionsInLastSevenDays: number | null
  }
  enhancedFeatures: EnhancedFeaturePermissionsObject
  partnerId: number
  type: EUserType
  useTwoFactor: boolean
  featureFlags?: UserFeatureFlag[]
  upcomingPromos?: UpcomingPromosInfo[]
  accountType: AccountType | null
  /**
   * twoFactorToken isn't technically part of the user object, but during 2FA we send a temporary object
   * with only `id` and `twoFactorToken`. Including it in the TUser interface facilitates doing conditional checks
   * between these states instead of typecasting two separate interfaces.
   */
  twoFactorToken?: string
}

export interface TSite {
  name: string
  storeId: number | null
}

export type TNonprofit = {
  actionLink?: string
  badge?: string
  causeId?: number
  causeNonprofits?: TCauseNonprofit[]
  communityPhoto?: string
  createdAt?: Date | string
  description?: string
  howToHelpBeyondBeam?: string
  donationLink?: string
  id?: number
  isDeleted?: boolean
  mission?: string
  name: string
  oldSdkId?: number
  personalPhoto?: string
  pointOfContactEmail?: string
  pointOfContactName?: string
  pointOfContactPhone?: string
  pointOfContactTitle?: string
  ppgfId?: string
  ppgfLink?: string
  regions?: string[]
  sendgridListId?: string
  updatedAt?: Date | string
  website?: string
}

export type TCauseNonprofit = {
  active?: boolean
  cause?: TCause
  causeDisplayIcon?: string
  causeDisplayName?: string
  causeId: number
  causeIdNonprofitId?: string
  causeSelectedImage?: string
  chainDonated: number
  chainId: number
  chainIdNonprofitId?: string
  createdAt?: Date | string
  filters?: string[]
  id?: number
  matchDonated: number
  nonprofit?: TNonprofit
  nonprofitId: number
  oldSdkId?: number
  selectedHexColor?: string
  sort_order?: number // for backwards compatability with sdk backend
  sortOrder?: number
  updatedAt?: Date | string
}

export type TCause = {
  id?: number
  name: string
  logo: string | null
  selectedImage?: string
  image?: string
  description?: string
  oldSdkId?: number
  createdAt?: Date | string
  updatedAt?: Date | string
}

export type InvoiceTypes = 'Beam' | 'Nonprofit'

export type InvoiceStatus = 'pending' | 'paid' | 'outstanding' | 'past_due' | 'partially_paid'

// Invoices
export interface TInvoice {
  beamInvoiceLink: string | null
  description: string
  dueDate: string | Date
  endDate: string | Date
  id: string
  isPaid: boolean | null
  lineItems?: Array<TLineItem | never>
  name: string | null
  paidOn: string | Date | null
  partiallyPaid: boolean
  partnerId: number
  partnerInvoiceLink: string | null
  payPalId: string | null
  paymentFailed: boolean
  startDate: string | Date
  type: InvoiceTypes
  invoiceStatus: InvoiceStatus
  autopayOutcome: string | null
  date: string
}

export interface TLineItem {
  amount: number
  description: string
  id: string
  isPaid: boolean | null
  linkToPay: string | null
  name: string
  status: string | null
  error: string | null
}

export interface PartnerUploadBody {
  id: string
  chainId: number
  fileName: string
  s3ObjectPath: string
  s3BucketName: string
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

// ================== Partner Impact ==================

export interface OverviewNonprofitImpactObject {
  name: string
  cause: string
  active: boolean
  personalPhoto: string
  communityPhoto: string
  donationLink: string
  actionLink: string
  ppgfLink: string
  pointOfContactName: string
  pointOfContactEmail: string
  pointOfContactPhone: string
  pointOfContactTitle: string
  target_image: string
  regions: string[]
  id: number
  isTrending: boolean
  impact: {
    total_donated: number
    target_donation_amount: number | null
  }
  storeNonprofit?: number | null
  associatedStores: {
    storeId: number
    name: string
  }[]
}

export interface OverviewPartnerImpactResponse {
  nonprofits: OverviewNonprofitImpactObject[]
}

export enum EnrollmentStatus {
  Ineligible = 'ineligible',
  NotEnrolled = 'not_enrolled',
  Trial = 'trial',
  Unlimited = 'unlimited',
}

export enum EnrollmentStatusDisplayName {
  Standard = 'Standard',
  Ineligible = 'Ineligible',
  GrowWithBeam = 'Grow with Beam',
}
export interface TEnrollmentDataResponse {
  enrollmentStatusDisplayName: EnrollmentStatusDisplayName
  enrollmentStatus: EnrollmentStatus
  renewalDate: string
}

/**
 * @deprecated - STOP USING THIS
 */
export interface V1PartnerResponse {
  canSeeAutopayFeatures: boolean
  id: number
  inKindDonations: boolean
  invoiceCcEmails: string[] | null
  invoiceEmail: string
  isActive: boolean
  launchDate: string | null
  name: string
  sdkId: number | null
}

export interface APICumulativeImpactResponse {
  cumulativeValue: number
  unit?: string | null
  description?: string | null
  icon?: string | null
}

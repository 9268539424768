import { API_BASE_PATH_PORTAL } from '../../api/auth'
import { axiosRequest } from '../../utils/axiosRequest'
import { setLoadingSupportEmail } from '../actions'

export const sendSupportEmail = (emailData, partnerId) => {
  return dispatch => {
    dispatch(setLoadingSupportEmail({ loading: true, error: null, success: null }))
    axiosRequest(
      'post',
      `${API_BASE_PATH_PORTAL}/v2/support`,
      {
        message: emailData.message,
        subject: emailData.subject,
        name: emailData.name,
        email: emailData.email,
      },
      { partnerId }
    )
      .then(() => {
        dispatch(setLoadingSupportEmail({ loading: false, error: null, success: true }))
      })
      .catch(error => {
        dispatch(setLoadingSupportEmail({ loading: false, error, success: false }))
      })
  }
}

import { createColumnHelper, Row } from '@tanstack/react-table'
import { noop, truncate } from 'lodash'
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { useBeamSelector } from '../../../../hooks'
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags'
import { BeamEditOrDeleteButton } from '../../../../stories/BeamEditOrDeleteButton'
import { BeamSticker } from '../../../../stories/BeamSticker'
import { BEAM_STICKER_VARIANT } from '../../../../stories/BeamSticker/BeamSticker'
import { BeamTable } from '../../../../stories/BeamTable'
import { BeamTooltip } from '../../../../stories/BeamTooltip'
import { formatDateTz, toInputDatetimeFormat } from '../../../../utils/helpers/formatDate'
import { TUser } from '../../../../utils/types'
import { useGtagWithContext } from '../../../root/BeamGoogleAnalytics/googleAnalyticsHelpers'
import { GrowWithBeamFeatureAccessButton } from '../../common/GrowWithBeamFeatureAccessButton'
import $$ from '../nonprofit-page.module.css'
import $$$ from './campaign-page.module.css'
import { ModalContext } from './CampaignPage'
import { CampaignTableLoadingOverlay } from './CampaignTableLoadingOverlay'
import {
  APIPromoObject,
  promoCampaignDisplayMap,
  PromoCampaignType,
  PromoModal,
} from './promo.types'

interface UpcomingAndLivePromosTableProps {
  data: APIPromoObject[]
  /**
   * Loading state for the table
   */
  loading: boolean
  user: TUser
}

interface PromosTableColumnsTypes extends APIPromoObject {
  edit: any
  socialShareAsset: string
}

export const expandedRowComponent = ({
  row,
  canUseInfluencerCampaign,
}: {
  row: { original: APIPromoObject }
  canUseInfluencerCampaign: boolean
}) => {
  const rowData = row.original
  const getPromoCampaignDisplayName = (type?: PromoCampaignType): string =>
    type ? promoCampaignDisplayMap[type] || 'Boost' : 'Boost'
  const type = getPromoCampaignDisplayName(rowData.type)

  return (
    <div className="grid grid-cols-4 gap-4 px-4">
      <div className="col-span-1">
        <p className={$$$.campaignDetailsHeader}>Campaign Name</p>
        <p className={$$$.campaignDetailsDatum}>{rowData.name}</p>
      </div>
      <div className="col-span-1">
        <p className={$$$.campaignDetailsHeader}>Type</p>
        <p className={$$$.campaignDetailsDatum}>{type}</p>
      </div>
      <div className="col-span-1">
        <p className={$$$.campaignDetailsHeader}>Nonprofits</p>
        <p className={$$$.campaignDetailsDatum}>{rowData.boostedNonprofits}</p>
      </div>
      <div className="col-span-1">
        <p className={$$$.campaignDetailsHeader}>Status</p>
        <p className={$$$.campaignDetailsDatum}>{renderBoostStatus(rowData.boostStatus)}</p>
      </div>
      <div className="col-span-1">
        <p className={$$$.campaignDetailsHeader}>Start Time</p>
        <p className={$$$.campaignDetailsDatum}>
          {formatDateTz(rowData.startTime, "LLL d, yyyy 'at' hh:mm aaa z")}
        </p>
      </div>
      <div className="col-span-1">
        <p className={$$$.campaignDetailsHeader}>End Time</p>
        <p className={$$$.campaignDetailsDatum}>
          {rowData.endTime && formatDateTz(rowData.endTime, "LLL d, yyyy 'at' hh:mm aaa z")}
        </p>
      </div>
      <div className="col-span-1">
        <p className={$$$.campaignDetailsHeader}>Boost Amount</p>
        <p className={$$$.campaignDetailsDatum}>{rowData.multiplier}x</p>
      </div>
      {canUseInfluencerCampaign && rowData?.type === PromoCampaignType.Affiliate ? ( //TODO: https://beamimpact.atlassian.net/browse/SDK-2566
        <div className="col-span-1">
          <p className={$$$.campaignDetailsHeader}>Tracking Links/Codes</p>
          <p className={$$$.campaignDetailsDatum}>
            <a>Download csv</a>
          </p>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

function promoCanBeEdited(promo: Row<PromosTableColumnsTypes>) {
  if (promo.original.type === PromoCampaignType.Affiliate) {
    return true
  }

  // does the promo start more than 24 hours from now
  return (
    promo?.original &&
    promo.original.boostStatus !== 'Live' &&
    new Date(promo.original.startTime).getTime() > new Date().setHours(new Date().getHours() + 24)
  )
}

function renderBoostStatus(boostStatus: string) {
  switch (boostStatus) {
    case 'Live':
      return <BeamSticker label={'🚀 Live'} variant={BEAM_STICKER_VARIANT.positive} />
    case 'Upcoming':
      return <BeamSticker label={'Upcoming'} variant={BEAM_STICKER_VARIANT.pending} />
    default:
      return boostStatus
  }
}

export const UpcomingAndLivePromosTable = (props: UpcomingAndLivePromosTableProps) => {
  const { setModal, setCurrentPromo } = useContext(ModalContext)
  const { data, loading = false } = props
  const columnHelper = createColumnHelper<PromosTableColumnsTypes>()
  const user: TUser | undefined = useBeamSelector(({ user }) => user)
  const hasSocialShareUploadEnhancedFeatureAccess =
    user?.enhancedFeatures.permissions.social_share_upload.access
  const gtag = useGtagWithContext()
  const featureFlags = useFeatureFlags()
  const canUseInfluencerCampaign = featureFlags['influencer-campaign']
  const canUseNewSocialShareForPromosUploadUI = featureFlags['social-share-for-promos-upload']
  const history = useHistory()

  function handleEditOrDeleteSelectEvent(selectedValue: 'edit' | 'delete', promo: APIPromoObject) {
    setCurrentPromo(promo)
    switch (selectedValue) {
      case 'delete':
        setModal(PromoModal.DeleteAPromoConfirmation)
        break
      case 'edit': {
        if (promo.type === PromoCampaignType.Affiliate) {
          history.push(`/nonprofits/campaigns/influencer/${promo.id}/edit`)
        } else {
          setModal(PromoModal.BoostANonprofit)
        }
        break
      }
    }
  }

  const convertRowToPromo = (rowData: PromosTableColumnsTypes): APIPromoObject => {
    const promoStartDateString = rowData?.startTime && toInputDatetimeFormat(rowData.startTime)
    const promoEndDateString = rowData?.endTime && toInputDatetimeFormat(rowData.endTime)
    return {
      id: rowData?.id,
      name: rowData?.name,
      promoText: rowData.promoText || null,
      chainId: rowData.chainId,
      availableToWholeChain: rowData?.availableToWholeChain,
      boostAllNonprofits: !!rowData?.boostAllNonprofits,
      boostedStoreNonprofitIds: rowData?.boostedStoreNonprofitIds,
      startTime: promoStartDateString,
      endTime: promoEndDateString,
      multiplier: rowData.multiplier,
      colorPrimary: rowData.colorPrimary,
      foregroundColor: rowData.foregroundColor,
      hasPromoAssetUrl: false,
      boostStatus: rowData.boostStatus || '',
      type: rowData.type,
      displayAffiliateName: rowData.displayAffiliateName,
    }
  }

  const columns = [
    columnHelper.accessor('edit', {
      header: 'Edit',
      cell: ({ row }) => {
        const disabled = !promoCanBeEdited(row)
        const promo = convertRowToPromo(row.original)

        return disabled ? (
          <BeamTooltip content="We can not edit live or upcoming campaigns launching within the next 24 hours. Please contact your CSL.">
            <BeamEditOrDeleteButton disabled={disabled} selectHandler={noop} />
          </BeamTooltip>
        ) : (
          <BeamEditOrDeleteButton
            disabled={disabled}
            selectHandler={(selectedValue: 'edit' | 'delete') => {
              handleEditOrDeleteSelectEvent(selectedValue, promo)
            }}
          />
        )
      },
    }),
    columnHelper.accessor('name', {
      header: 'Campaign Name',
    }),
    ...(canUseInfluencerCampaign
      ? [
          columnHelper.accessor('type', {
            header: 'Type',
            cell: ({ row }) => {
              const rowData = row.original
              const getPromoCampaignDisplayName = (type?: PromoCampaignType): string =>
                type ? promoCampaignDisplayMap[type] || 'Boost' : 'Boost' //TODO: https://beamimpact.atlassian.net/browse/SDK-2566
              const type = getPromoCampaignDisplayName(rowData.type)

              return <>{type}</>
            },
          }),
        ]
      : []),
    columnHelper.accessor('startTime', {
      header: `Date Range`,
      cell: ({ row }) => {
        const rowData = row.original

        return (
          <>
            {formatDateTz(rowData.startTime, 'MM/dd')}
            {rowData.endTime ? `- ${formatDateTz(rowData.endTime, 'MM/dd')}` : ''}
          </>
        )
      },
    }),
    ...(!canUseInfluencerCampaign
      ? [
          columnHelper.accessor('multiplier', {
            header: 'Boost Amount',
            cell: ({ row }) => {
              const rowData = row.original

              return <>{rowData.multiplier}x</>
            },
          }),
        ]
      : []),
    ...(!canUseInfluencerCampaign
      ? [
          columnHelper.accessor('boostedNonprofits', {
            header: 'Boosted Nonprofits',
            cell: ({ row }) => {
              const rowData = row.original

              return (
                <BeamTooltip content={rowData.boostedNonprofits}>
                  {truncate(rowData.boostedNonprofits, {
                    length: 50,
                    omission: '...',
                  })}
                </BeamTooltip>
              )
            },
          }),
        ]
      : []),
    columnHelper.accessor('boostStatus', {
      header: 'Status',
      cell: ({ row }) => {
        const rowData = row.original
        return renderBoostStatus(rowData.boostStatus)
      },
    }),
    ...(canUseNewSocialShareForPromosUploadUI
      ? [
          columnHelper.accessor('socialShareAsset', {
            header: 'Social Share',
            cell: ({ row }) => {
              const campaignHasSocialShareAsset = row.original.hasPromoAssetUrl
              const campaignHasBoostedNonprofits = row.original.boostedStoreNonprofitIds?.length > 0

              if (campaignHasBoostedNonprofits) {
                return campaignHasSocialShareAsset && hasSocialShareUploadEnhancedFeatureAccess ? (
                  <a
                    className={$$.link}
                    onClick={() => {
                      setCurrentPromo(convertRowToPromo(row.original))
                      setModal(PromoModal.PromoSocialShareUpload)
                      gtag('campaigns_management_page | add_asset')
                    }}>
                    View/Edit
                  </a>
                ) : (
                  <GrowWithBeamFeatureAccessButton
                    label="Add"
                    block
                    size="small"
                    onClick={() => {
                      setCurrentPromo(convertRowToPromo(row.original))
                      setModal(PromoModal.PromoSocialShareUpload)
                      gtag('campaigns_management_page | view_edit_asset')
                    }}
                  />
                )
              }
              return null
            },
          }),
        ]
      : []),
  ]

  return (
    <div className={'relative'} aria-live={'polite'}>
      <CampaignTableLoadingOverlay loading={loading} />
      <BeamTable
        columns={columns}
        data={data}
        noDataMessage={'No reports uploaded to Partner Portal yet'}
        enableSorting
        expandedRowComponent={props => expandedRowComponent({ ...props, canUseInfluencerCampaign })}
      />
    </div>
  )
}

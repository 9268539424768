import { BeamDropdown } from '../../../../stories/BeamDropdown'
import { BEAM_DROPDOWN_OPTION } from '../../../../stories/BeamDropdown/BeamDropdown'
import { BeamDropdownOnChangeEvent } from '../../../../stories/BeamDropdown/BeamDropdown.types'
import { BeamTooltip } from '../../../../stories/BeamTooltip'
import $$ from './campaign-page.module.css'
import { GWB_LOCKED_CTA_COPY } from './CampaignPage'

interface CampaignDropdownProps {
  onChange: (e: BeamDropdownOnChangeEvent) => void
  disabled: boolean
  dropdownOptions: BEAM_DROPDOWN_OPTION[]
  disabledCopy?: string
}

export const CampaignDropdown = ({
  onChange,
  disabled,
  disabledCopy = GWB_LOCKED_CTA_COPY, //TODO: https://beamimpact.atlassian.net/browse/SDK-2566
  dropdownOptions,
}: CampaignDropdownProps) => {
  return disabled ? (
    <BeamTooltip content={disabledCopy} placement={'left'}>
      <BeamDropdown
        placeholder="Run a Campaign"
        value="Run a Campaign"
        className={`col-span-2 ${$$['campaignDropdown']}`}
        disabled={disabled}
        options={dropdownOptions}
        hoist={true}
        onChange={onChange}
      />
    </BeamTooltip>
  ) : (
    <BeamDropdown
      placeholder="Run a Campaign"
      value="Run a Campaign"
      className={`col-span-2 ${$$['campaignDropdown']}`}
      disabled={disabled}
      options={dropdownOptions}
      hoist={true}
      onChange={onChange}
    />
  )
}

import { createColumnHelper } from '@tanstack/react-table'
import { truncate } from 'lodash'

import { useFeatureFlags } from '../../../../hooks/useFeatureFlags'
import { BeamTable } from '../../../../stories/BeamTable'
import { formatDateTz } from '../../../../utils/helpers/formatDate'
import { CampaignTableLoadingOverlay } from './CampaignTableLoadingOverlay'
import { APIPromoObject, promoCampaignDisplayMap, PromoCampaignType } from './promo.types'
import { expandedRowComponent } from './UpcomingAndLivePromosTable'

interface CompletedPromosTableProps {
  data: APIPromoObject[]
  /**
   * Loading state for the table
   */
  loading: boolean
}

export const CompletedPromosTable = (props: CompletedPromosTableProps) => {
  const { data, loading = false } = props
  const columnHelper = createColumnHelper<APIPromoObject>()
  const featureFlags = useFeatureFlags()
  const canUseInfluencerCampaign = featureFlags['influencer-campaign']
  const getPromoCampaignDisplayName = (type?: PromoCampaignType): string =>
    type ? promoCampaignDisplayMap[type] || 'Boost' : 'Boost' //TODO: https://beamimpact.atlassian.net/browse/SDK-2566

  const columns = [
    columnHelper.accessor('name', {
      header: 'Campaign Name',
    }),
    ...(canUseInfluencerCampaign
      ? [
          columnHelper.accessor('type', {
            header: 'Type',
            cell: ({ row }) => {
              const rowData = row.original
              const type = getPromoCampaignDisplayName(rowData.type)

              return <>{type}</>
            },
          }),
        ]
      : []),
    columnHelper.accessor('startTime', {
      header: 'Date Range',
      cell: ({ row }) => {
        const rowData = row.original

        return (
          <>
            {formatDateTz(rowData.startTime, 'MM/dd')}
            {rowData.endTime ? `- ${formatDateTz(rowData.endTime, 'MM/dd')}` : ''}
          </>
        )
      },
    }),
    columnHelper.accessor('multiplier', {
      header: 'Boost Amount',
      cell: ({ row }) => {
        const rowData = row.original

        return <>{rowData.multiplier}x</>
      },
    }),
    ...(!canUseInfluencerCampaign
      ? [
          columnHelper.accessor('boostedNonprofits', {
            header: 'Boosted Nonprofits',
            cell: ({ row }) => {
              const rowData = row.original

              return (
                <>
                  {truncate(rowData.boostedNonprofits, {
                    length: 50,
                    omission: '...',
                  })}
                </>
              )
            },
          }),
        ]
      : []),
  ]

  return (
    <div className={'relative'} aria-live={'polite'}>
      <CampaignTableLoadingOverlay loading={loading} />

      <BeamTable
        columns={columns}
        data={data}
        noDataMessage={'No reports uploaded to Partner Portal yet'}
        enableSorting
        expandedRowComponent={props => expandedRowComponent({ ...props, canUseInfluencerCampaign })}
      />
    </div>
  )
}

import { SlIcon } from '@shoelace-style/shoelace/dist/react'
import SlTree from '@shoelace-style/shoelace/dist/react/tree'
import SlTreeItem from '@shoelace-style/shoelace/dist/react/tree-item'
import cx from 'classnames'
import { toString } from 'lodash'
import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { useGtagWithContext } from '../../components/root/BeamGoogleAnalytics/googleAnalyticsHelpers'
import { useBeamSelector } from '../../hooks'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import { getCurrentPageTitle } from '../../utils/getCurrentPageTitle'
import { getUserAccessScopes } from '../../utils/getUserAccessScopes'
import { EUserType, TSite, TUser } from '../../utils/types'
import { BeamDropdown } from '../BeamDropdown'
import { BEAM_DROPDOWN_OPTION } from '../BeamDropdown/BeamDropdown'
import { ReactComponent as BarGraph } from './assets/barGraph.svg'
import { ReactComponent as BulletList } from './assets/bulletList.svg'
import { ReactComponent as DownArrow } from './assets/downArrow.svg'
import { ReactComponent as Folder } from './assets/folder.svg'
import { ReactComponent as NonprofitPartners } from './assets/nonprofitPartners.svg'
import { ReactComponent as CampaignManagement } from './assets/rocketIcon.svg'
import { ReactComponent as UserAvatar } from './assets/userAvatar.svg'
import $$ from './beam-side-navigation.module.css'
import { BeamSideNavigationLink } from './BeamSideNavigationLink'
import { BeamUserBadge } from './BeamUserBadge'

interface PartnerSideNavBarProps {
  user: TUser
  pathname: string | null
  site?: TSite
  toggleFunction?: () => void
  invoicesCount?: number
  showMultiSiteFeatures?: boolean
  siteDropdownOptions?: BEAM_DROPDOWN_OPTION[]
  showSiteDropDown?: boolean
  handleSiteChange?: (_: any, value: number) => void
}
enum NavItemType {
  link = 'link',
  tree = 'tree',
  line = 'line',
}

const HeaderNavItem = ({ label }: { label: string }) => {
  return (
    <div className={$$['beam-navigation--section-header']} key={label}>
      {label}
    </div>
  )
}
const LinkNavItem = ({
  label,
  link,
  icon,
  pathname,
  userType,
}: {
  label: string
  link: string
  icon?: ReactNode
  pathname: string | null
  userType: EUserType
}) => {
  const accessScopes = getUserAccessScopes(userType)
  const shouldShow = accessScopes?.some(scope => link.includes(scope)) || accessScopes === null

  if (!shouldShow) {
    return null
  }

  return (
    <BeamSideNavigationLink
      key={label}
      path={link}
      itemLabel={label}
      active={pathname?.endsWith(link)}
      className={cx($$.navLink)}>
      {icon ?? <></>}
      <span className={$$['beam-navigation--link-label']}>{label}</span>
    </BeamSideNavigationLink>
  )
}

const DividerNavItem = ({ id }: { id: string }) => {
  return (
    <hr
      className={`mt-6 mx-4`}
      style={{ borderColor: 'var(--beam-color--charcoal-200)' }}
      key={id}
    />
  )
}

interface NavSectionItem {
  type: NavItemType.link | NavItemType.line
  label: string
  link: string
  icon?: ReactNode
  isExternal?: boolean
}

const TreeNavItem = ({
  label,
  treeItems,
  pathname,
  userType,
}: {
  label: string
  treeItems: NavSectionItem[]
  pathname: string | null
  userType: EUserType
}) => {
  const isExpanded = treeItems.some(item => pathname?.endsWith(item.link))
  const accessScopes = getUserAccessScopes(userType)

  // Filter out links that the user has no access to.
  const filteredTreeItems = treeItems.filter(treeItem => {
    return (
      accessScopes?.some(scope => treeItem.link.includes(scope)) ||
      accessScopes === null ||
      treeItem.isExternal
    )
  })

  return (
    <SlTree className={$$['beam-navigation--tree']}>
      <SlIcon
        name={'chevron-right'}
        slot={'expand-icon'}
        library={'system'}
        style={{ transform: 'rotate(90deg)' }}
      />
      <SlIcon
        name={'chevron-right'}
        slot={'collapse-icon'}
        library={'system'}
        style={{ transform: 'rotate(180deg)' }}
      />

      <SlTreeItem
        className={cx($$['beam-navigation--tree--header'], {
          [$$.active]: isExpanded,
        })}
        expanded={isExpanded}>
        <SlIcon
          name="wrench"
          library="system"
          className={$$['beam-navigation--tree--header-icon']}
          style={{ width: '24px', height: '24px', fill: 'none', stroke: 'currentColor' }}
        />
        <Link
          to={'/dev-console/info'}
          className={cx(
            $$['beam-navigation--link-label'],
            $$['beam-navigation--tree--header-label'],
            {
              [$$.active]: isExpanded,
            }
          )}>
          {label}
        </Link>
        {filteredTreeItems?.map(item => {
          return (
            <SlTreeItem key={item.link} className={cx($$['nav-tree-child-link'])}>
              <BeamSideNavigationLink
                key={item.link}
                path={item.link}
                itemLabel={item.label}
                active={pathname?.endsWith(item.link)}
                className={cx($$.navLink)}>
                <span className={$$['beam-navigation--link-label']}>{item.label}</span>
                {item.isExternal && (
                  <SlIcon
                    library={'material'}
                    name={'open_in_new'}
                    style={{ width: '16px', height: '16px' }}
                    className={cx($$['navLink-suffix-icon'])}
                  />
                )}
              </BeamSideNavigationLink>
            </SlTreeItem>
          )
        })}
      </SlTreeItem>
    </SlTree>
  )
}

export const BeamSideNavigation = ({
  user,
  pathname,
  site,
  toggleFunction,
  showMultiSiteFeatures = false,
  siteDropdownOptions = [],
  handleSiteChange,
}: PartnerSideNavBarProps) => {
  const siteFilter: TSite | undefined = useBeamSelector(({ site }) => site)
  const featureFlags = useFeatureFlags()
  const isEngineerUserType = !!user?.type && user.type === EUserType.Engineering

  // This is added to allow dropdown clicks while the left nav is docked
  const handleNavClick = (e: any) => {
    if (
      e.target instanceof HTMLAnchorElement ||
      (e.target as HTMLElement).parentElement instanceof HTMLAnchorElement
    ) {
      toggleFunction && toggleFunction()
    }
  }
  const gtag = useGtagWithContext()

  const gtagMetadata = {
    siteFilter: site?.name || null,
    pageWhereEventTriggered: getCurrentPageTitle(),
  }

  return (
    <div className={$$['beam-navigation']} onClick={handleNavClick} onKeyDown={handleNavClick}>
      {showMultiSiteFeatures && !isEngineerUserType && (
        <BeamDropdown
          label=""
          value={toString(site?.storeId || 0)}
          className={$$['beam-navigation--site-drop-down']}
          options={siteDropdownOptions}
          onClickCallback={() => {
            gtag(`Multi-site dropdown clicked`, gtagMetadata)
          }}
          onChange={(e: any) => {
            const storeId = e.target.value as string
            handleSiteChange && handleSiteChange(e, +storeId)
            toggleFunction && toggleFunction()
            gtag(`Site filter applied`, gtagMetadata)
          }}>
          <div slot="expand-icon">
            <DownArrow style={{ fill: 'var(--beam-color--charcoal-50) !important' }} />
          </div>
        </BeamDropdown>
      )}

      <div className={$$['nav-block']}>
        <HeaderNavItem label={'Analytics & Reports'} />
        <LinkNavItem
          label={'Overview'}
          link={'/overview'}
          icon={<BulletList />}
          pathname={pathname}
          userType={user.type}
        />
        <LinkNavItem
          label={'Reports & Analytics'}
          link={'/reports'}
          icon={<BarGraph />}
          pathname={pathname}
          userType={user.type}
        />
      </div>

      <div className={$$['nav-block']}>
        <HeaderNavItem label={'Nonprofit Management'} />
        <LinkNavItem
          label={'Nonprofit Partners'}
          link={'/nonprofits'}
          icon={<NonprofitPartners />}
          pathname={pathname}
          userType={user.type}
        />
      </div>

      {!!siteFilter?.storeId && <DividerNavItem id={'divider-1'} />}

      <div className={$$['nav-block']}>
        <HeaderNavItem label={'Marketing Activations'} />
        <LinkNavItem
          label={'Campaign Management'}
          link={'/nonprofits/campaigns'}
          icon={<CampaignManagement />}
          pathname={pathname}
          userType={user.type}
        />
      </div>

      <div className={$$['nav-block']}>
        <HeaderNavItem label={'Billing & Support'} />
        <LinkNavItem
          label={'Invoices'}
          link={'/invoices'}
          icon={<Folder />}
          pathname={pathname}
          userType={user.type}
        />
        <LinkNavItem
          label={'Contact Support'}
          link={'/contact-support'}
          icon={<UserAvatar />}
          pathname={pathname}
          userType={user.type}
        />

        {featureFlags['can-see-dev-console-links'] && (
          <div className={cx($$['nav-tree-wrapper'])}>
            <TreeNavItem
              label={'Developer Tools'}
              treeItems={[
                { type: NavItemType.link, label: 'API Keys & IDs', link: '/dev-console/info' },
                {
                  type: NavItemType.link,
                  label: 'Transactions',
                  link: '/dev-console/transactions',
                },
                {
                  type: NavItemType.link,
                  label: 'Documentation',
                  link: 'https://docs.beamimpact.com/docs/introduction/what-is-beam',
                  isExternal: true,
                },
              ]}
              pathname={pathname}
              userType={user.type}
            />
          </div>
        )}
      </div>

      <BeamUserBadge user={user} pathname={pathname} />
    </div>
  )
}

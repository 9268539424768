import _ from 'lodash'

import {
  CreatePromoRequestBody,
  UpdateAutoBoostRequestBody,
} from '../../AutoBoostANonprofitModal.types'
import {
  APIPromoCodeGroup,
  APIValidateAffiliateCodeCsvResponse,
  GetPromoByIdResponse,
  PromoCampaignType,
} from '../promo.types'
import { InfluencerFormData } from './new/data-store'

export function mapPromoDataToUpdatePromoBody(
  data: GetPromoByIdResponse
): UpdateAutoBoostRequestBody {
  return {
    ..._.pick(data, [
      'availableToWholeChain',
      'boostAllNonprofits',
      'boostedStoreNonprofitIds',
      'chainId',
      'colorPrimary',
      'endTime',
      'foregroundColor',
      'id',
      'multiplier',
      'name',
      'pdpColorPrimary',
      'pdpTextColorPrimary',
      'promoCodes',
      'promoText',
      'skus',
      'startTime',
      'textColorPrimary',
    ]),
    id: Number(data.id),
    sortOrder: data.config.sortOrder,
    nonprofitSwaps: data.config.nonprofitSwaps,
  }
}

export const PROMO_GROUPS_CSV_DEFAULT_SCHEMA = {
  name: 'influencer',
  version: '1.0.0',
}

export async function convertCsvFileToString(file: File) {
  const csvString = await file.text()
  const sanitizedFile = csvString.replace(/\r\n/g, '\n') // Normalize line endings
  return sanitizedFile
}

/**
 * Maps the influencer form data into the necessary request body for promo CREATE
 */
export async function mapInfluencerFormDataToPromoCreationRequestBody({
  formData,
  chainId,
}: {
  formData: InfluencerFormData
  chainId: number
}): Promise<CreatePromoRequestBody> {
  return {
    chainId,
    name: formData.campaignName,
    boostedStoreNonprofitIds: formData.nonprofitIds.map(Number),
    startTime: new Date(formData.boostStartDate).toISOString(),
    endTime: formData.boostEndDate ? new Date(formData.boostEndDate).toISOString() : undefined,
    multiplier: formData.boostMultiplier,
    colorPrimary: formData.campaignPillColor,
    foregroundColor: formData.campaignTextColor,
    type: PromoCampaignType.Affiliate,
    displayAffiliateName: formData.displayAffiliateName,
    promoCodeGroupsCSV: formData.fileUpload?.file
      ? {
          value: await convertCsvFileToString(formData.fileUpload?.file),
          schema: PROMO_GROUPS_CSV_DEFAULT_SCHEMA,
        }
      : undefined,
  }
}

export function countPromoCodes(promoCodeGroups: APIPromoCodeGroup[] | null) {
  if (!promoCodeGroups) {
    return 0
  }

  let totalPromoCodesCount = 0
  for (const promoCodeGroup of promoCodeGroups) {
    totalPromoCodesCount += promoCodeGroup.promoCodes?.length || 0
  }

  return totalPromoCodesCount
}

export const mockAPIValidateAffiliateCodeCsvResponse: APIValidateAffiliateCodeCsvResponse = {
  errors: [],
  value: {
    promoCodeGroups: [
      {
        name: 'Group 1',
        promoCodes: [
          {
            type: 'manual',
            attributes: {
              value: 'DISCOUNT10',
            },
          },
          {
            type: 'query_param',
            attributes: {
              queryParams: [
                {
                  key: 'id',
                  value: '12345',
                },
                {
                  key: 'discount',
                  value: '50',
                },
              ],
            },
          },
        ],
      },
      {
        name: 'Group 2',
        promoCodes: [
          {
            type: 'manual',
            attributes: {
              value: 'SAVE20',
            },
          },
        ],
      },
    ],
  },
}

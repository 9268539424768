import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { overviewWithSubscriptionsFeaturePathname } from '../../root/Root'
import { OverviewPage } from './OverviewPage'
import { OverviewPageWithSubscriptions } from './OverviewPageWithSubscriptions'

/**
 * Determines the appropriate component to render for the overview page route based on feature flags.
 * Prevents unnecessary effects from being triggered.
 */
export function OverviewPageRouteDispatcher() {
  const featureFlag = useFeatureFlags()

  const isFeatureRoute = location.pathname.includes(overviewWithSubscriptionsFeaturePathname)
  if (featureFlag['disable-subscription-reporting'] && !isFeatureRoute) {
    return <OverviewPage />
  }

  return <OverviewPageWithSubscriptions />
}

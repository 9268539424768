import { useState } from 'react'

import {
  InfluencerFormContext,
  InfluencerPromoFormModal,
} from '../components/InfluencerFormContext'
import { InfluencerCampaignForm } from '../InfluencerCampaignForm'
import { InfluencerCampaignPageLayout } from '../layout'

export const InfluencerCampaignPage = () => {
  const formId = 'new-influencer-campaign-form'
  const [activeModal, setActiveModal] = useState<InfluencerPromoFormModal | null>(null)

  return (
    <InfluencerFormContext.Provider
      value={{
        formId,
        promoData: undefined,
        modalState: {
          activeModal,
          setActiveModal,
          handleCloseModal: () => setActiveModal(null),
        },
      }}>
      <InfluencerCampaignPageLayout>
        <InfluencerCampaignForm />
      </InfluencerCampaignPageLayout>
    </InfluencerFormContext.Provider>
  )
}

import { delay } from 'lodash'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'

import { getV2ApiUrl } from '../../../../../../api/auth'
import { useBeamSelector } from '../../../../../../hooks'
import { useAsyncBeamFetch } from '../../../../../../hooks/useAsyncBeamFetch'
import { BeamButton } from '../../../../../../stories/BeamButton'
import { BeamLoadingIndicator } from '../../../../../../stories/BeamLoadingIndicator'
import { BeamModal } from '../../../../../../stories/BeamModal'
import { TUser } from '../../../../../../utils/types'
import { useGlobalNotifications } from '../../../../../root/context/GlobalNotificationContext'
import { BeamSlIcon } from '../../../../common/BeamSlIcon'
import { DeletePromoReqBody, DeletePromoResponse } from '../../promo.types'

interface DeleteCampaignModalProps {
  isOpen: boolean
  handleClose: () => void
}
export const DeleteCampaignModal = ({ isOpen = false, handleClose }: DeleteCampaignModalProps) => {
  const { campaignId }: { campaignId: string } = useParams()
  const history = useHistory()
  const user: TUser = useBeamSelector(({ user }) => user)
  const { notify } = useGlobalNotifications()

  const {
    fetchData: deletePromo,
    data: deleteResponse,
    isFetching: deletePending,
    error,
  } = useAsyncBeamFetch<DeletePromoResponse, DeletePromoReqBody>(
    getV2ApiUrl(`/promotions/delete`),
    {
      method: 'DELETE',
      data: {
        promoId: Number(campaignId),
        partnerId: user.partnerId,
      },
    }
  )

  const handleConfirm = async () => {
    await deletePromo()
  }

  useEffect(
    function showErrorToastOnFailure() {
      if (error) {
        notify({
          variant: 'error',
          text: error,
        })
      }
    },
    [error, notify]
  )

  useEffect(function redirectOnSuccess() {
    if (deleteResponse) {
      notify({
        variant: 'success',
        text: 'Campaign ended successfully',
        icon: <BeamSlIcon library={'system'} name={'checkCircle'} />,
      })
      delay(() => {
        history.push('/nonprofits/campaigns')
      }, 500)
    }
  })

  return (
    <BeamModal
      open={isOpen}
      label="Are you sure you want to delete this campaign?"
      disableClose={deletePending}
      body={
        <>
          <div className={'text-center relative'}>
            {deletePending && <BeamLoadingIndicator withBackdrop />}
            <p
              className={
                'leading-normal font-secondary text-md text-charcoal-800 text-center my-4 max-w-[350px] mx-auto'
              }>
              Deleting this campaign means it will be removed from campaign management.
              <span className={'block pt-4'}>Would you like to delete?</span>
            </p>
          </div>
        </>
      }
      footer={
        <div slot={'footer'} className={'flex flex-col max-w-[350px] mx-auto'}>
          <BeamButton
            className={'mt-4'}
            variant={'emphasis_white'}
            onClick={handleConfirm}
            type={'button'}
            disabled={deletePending}>
            <span className={'text-cherry-800'}>Yes, Delete Campaign</span>
            <BeamSlIcon
              library={'system'}
              name={'trash'}
              slot={'prefix'}
              className={'w-6 h-6 text-cherry-800'}
            />
          </BeamButton>
          <BeamButton
            className={'mt-4'}
            onClick={handleClose}
            type={'button'}
            disabled={deletePending}>
            No, Don&apos;t Delete Campaign
          </BeamButton>
        </div>
      }
      onCloseCallback={handleClose}
    />
  )
}

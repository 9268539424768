import { FileWithPath } from 'react-dropzone'

import {
  AcceptedFileType,
  DropzoneAcceptPropType,
  FileWithDisplayName,
} from './beam-file-dropzone-types'

const fileTypesMap: Record<AcceptedFileType, DropzoneAcceptPropType> = {
  '.pdf': {
    'application/pdf': ['.pdf'],
  },
  '.csv': {
    'text/csv': ['.csv'],
  },
  '.json': {
    'application/json': ['.json'],
  },
  '.jpg': {
    'image/jpeg': ['.jpg', '.jpeg'],
  },
  '.png': {
    'image/png': ['.png'],
  },
}

/**
 * Maps the `accept` prop to the required format for react-dropzone.
 *
 * MIME types reference: https://developer.mozilla.org/en-US/docs/Web/HTTP/Guides/MIME_types/Common_types
 * react-dropzone reference: https://react-dropzone.js.org/#section-accepting-specific-file-types
 */
export function formatAcceptProp(
  accept: AcceptedFileType[] | undefined
): DropzoneAcceptPropType | undefined {
  return accept?.reduce((acc: DropzoneAcceptPropType, current) => {
    if (!acc) {
      acc = {}
    }

    acc = { ...acc, ...fileTypesMap[current] }
    return acc
  }, {})
}

export function convertToFileWithDisplayName(
  file: FileWithPath | File,
  promoCodesCount?: number
): FileWithDisplayName {
  return {
    file,
    displayName: promoCodesCount
      ? `${file.name} (${promoCodesCount} link${promoCodesCount > 1 ? 's' : ''})`
      : file.name,
  }
}

export const BEAM_COLORS = {
  blue: '#92c1cc',
  orange: '#f7961d',
  yellow: '#fcd55f',
  green: '#b8cfa3',
  darkYellow: '#ffc000',
  darkOrange: '#F78332',
  yellowOrangeGradient: 'linear-gradient(270deg, #f7961d, #fcd55f )',
  darkGray: '#808191',
  lightGray: '#e4e4e4',
  red: '#FC5F6E',
  orangeYellowGradient: 'linear-gradient(270deg, #FBAB7E, #F7CE68)',
  darkTeal: '#00a0b5',
  darkGreen: '#42CA7C',
}

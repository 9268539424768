import { isNil } from 'lodash'
import { createContext, ReactNode, useCallback, useContext, useState } from 'react'

import { BeamToast, BeamToastProps } from '../../../stories/BeamToast/BeamToast'

type NotifyFunctionParams = Omit<BeamToastProps, 'onClose' | 'open'>

interface GlobalNotificationsContextProps {
  notify: (props: NotifyFunctionParams) => void
}

export const GlobalNotificationsContext = createContext<GlobalNotificationsContextProps>({
  notify: () => null,
})

export const useGlobalNotifications = () => {
  const { notify } = useContext(GlobalNotificationsContext)
  return { notify }
}

export const GlobalNotificationsContextProvider = ({ children }: { children: ReactNode }) => {
  const [notification, setNotification] = useState<NotifyFunctionParams | null>(null)
  const MAX_TOAST_DURATION = 5000
  const shouldLimitDuration =
    notification?.duration === Infinity && notification.variant !== 'error' // Error alerts are usually closable and we want the user to be able to read it.

  const notify = useCallback((props: NotifyFunctionParams) => {
    setNotification(props)
  }, [])

  const onClose = useCallback(() => {
    setNotification(null)
  }, [])

  return (
    <GlobalNotificationsContext.Provider value={{ notify }}>
      <BeamToast
        {...(notification || ({} as any))}
        duration={shouldLimitDuration ? MAX_TOAST_DURATION : notification?.duration}
        open={!isNil(notification)}
        onClose={onClose}
        closable={true}
      />
      {children}
    </GlobalNotificationsContext.Provider>
  )
}

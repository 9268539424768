export function checkIsLivePromo({
  startTime,
  endTime,
}: {
  startTime: string | Date
  endTime: string | Date | null
}) {
  const now = new Date()
  const start = new Date(startTime)

  if (endTime === null) {
    return now >= start
  }

  const end = new Date(endTime)
  return now >= start && now <= end
}

export function checkIsUpcomingPromo({ startTime }: { startTime: string | Date }) {
  const now = new Date()
  const start = new Date(startTime)
  return now < start
}

export function checkIsPastPromo({ endTime }: { endTime: string | Date }) {
  const now = new Date()
  const end = new Date(endTime)
  return now > end
}

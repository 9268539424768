import cx from 'classnames'
import { ReactNode } from 'react'

export const PageHeaderContainer = ({
  children,
  withBackground = false,
}: {
  children: ReactNode
  withBackground?: boolean
}) => {
  return (
    <div
      className={cx('beam--page-content--no-gutter px-5 py-6', {
        'bg-charcoal-50': withBackground,
      })}>
      {children}
    </div>
  )
}

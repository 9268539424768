import { API_BASE_PATH_PORTAL } from '../../../api/auth'
import { axiosRequest } from '../../../utils/axiosRequest'
import { CustomErrorClass } from '../../../utils/CustomErrorClass'
import { TUser } from '../../../utils/types'
import { CustomError } from '../../../utils/types/CustomError'
import {
  APIAutoBoostRequest,
  APIUpdateAutoBoostRequest,
  CreatePromoAPIResponse,
  CreatePromoRequestBody,
  UpdateAutoBoostRequestBody,
  UpdateAutoBoostResponse,
} from './AutoBoostANonprofitModal.types'

function validateMultiplier(multiplier: string | number): number | undefined {
  const parsedMultiplier = Number(multiplier)
  if (isNaN(parsedMultiplier)) {
    throw new Error('Invalid multiplier')
  }
  return parsedMultiplier
}

function validateAutoBoostRequestData(
  requestData: CreatePromoRequestBody | UpdateAutoBoostRequestBody,
  user: TUser
) {
  if (!user || user.chainId == null) {
    throw new Error('Invalid user info')
  }

  const { boostedStoreNonprofitIds } = requestData
  const sanitizedBoostedStoreNonprofitIds =
    boostedStoreNonprofitIds?.filter(id => !isNaN(Number(id))).map(Number) || []

  if (sanitizedBoostedStoreNonprofitIds.length !== boostedStoreNonprofitIds?.length) {
    throw new Error('Invalid boosted nonprofit Ids')
  }

  // convert dates to UTC before sending it to the backend
  const startTimeInUTC = requestData.startTime
    ? new Date(requestData.startTime || '').toISOString()
    : requestData.startTime
  const endTimeInUTC = requestData.endTime
    ? new Date(requestData.endTime || '').toISOString()
    : requestData.endTime

  return { sanitizedBoostedStoreNonprofitIds, startTimeInUTC, endTimeInUTC }
}

/**
 * @throws Error
 */
export async function createAutoBoost({
  requestData,
  promoText,
  user,
  partnerId,
}: APIAutoBoostRequest): Promise<CreatePromoAPIResponse> {
  const { multiplier, colorPrimary, foregroundColor } = requestData
  const { sanitizedBoostedStoreNonprofitIds, startTimeInUTC, endTimeInUTC } =
    validateAutoBoostRequestData(requestData, user)
  const validatedMultiplier = multiplier ? validateMultiplier(multiplier) : undefined

  const requestDataToSend: CreatePromoRequestBody = {
    promoText,
    colorPrimary,
    foregroundColor,
    name: requestData.name,
    chainId: user.chainId,
    availableToWholeChain: false,
    boostAllNonprofits: false,
    boostedStoreNonprofitIds: sanitizedBoostedStoreNonprofitIds,
    startTime: startTimeInUTC || '',
    endTime: endTimeInUTC,
    multiplier: validatedMultiplier,
  }

  try {
    const response = await axiosRequest(
      'post',
      `${API_BASE_PATH_PORTAL}/v2/promotions?partnerId=${partnerId}`,
      requestDataToSend
    )
    return response.data as CreatePromoAPIResponse
  } catch (error: any) {
    const errorDetail = error.response?.data?.detail
    const errorCode = error.response?.data?.code

    const customError: CustomError = {
      code: errorCode,
      detail: errorDetail,
    }
    throw new CustomErrorClass(customError)
  }
}

export async function updateAutoBoost({ requestData, promoText, user }: APIUpdateAutoBoostRequest) {
  const { multiplier, colorPrimary } = requestData
  const { sanitizedBoostedStoreNonprofitIds, startTimeInUTC, endTimeInUTC } =
    validateAutoBoostRequestData(requestData, user)
  const validatedMultiplier = multiplier ? validateMultiplier(multiplier) : undefined

  const requestDataToSend: UpdateAutoBoostRequestBody = {
    id: Number(requestData.id),
    promoText,
    colorPrimary,
    name: requestData.name,
    chainId: user.chainId,
    availableToWholeChain: false,
    boostAllNonprofits: false,
    boostedStoreNonprofitIds: sanitizedBoostedStoreNonprofitIds,
    startTime: startTimeInUTC,
    endTime: endTimeInUTC,
    multiplier: validatedMultiplier,
  }

  try {
    const response = await axiosRequest(
      'post',
      `${API_BASE_PATH_PORTAL}/v2/promotions/update?partnerId=${user.partnerId}`,
      requestDataToSend
    )
    return response.data as UpdateAutoBoostResponse
  } catch (error: any) {
    const errorDetail = error.response?.data?.detail
    const errorCode = error.response?.data?.code

    const customError: CustomError = {
      code: errorCode,
      detail: errorDetail,
    }
    throw new CustomErrorClass(customError)
  }
}

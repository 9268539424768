/* eslint-disable prefer-rest-params */
import './beam-dropdown.css'

import type SlInputElement from '@shoelace-style/shoelace/dist/components/input/input'
import SlSelectElement from '@shoelace-style/shoelace/dist/components/select/select'
import { SlIcon, SlOption, SlSelect } from '@shoelace-style/shoelace/dist/react'
import cx from 'classnames'
import { camelCase } from 'lodash'
import { forwardRef } from 'react'

import { LockIcon } from '../../components/root/iconComponents/LockIcon'
import { BeamTooltip } from '../BeamTooltip'
import { BeamDSProps } from '../interface'
import {
  BeamDropdownOnChangeHandler,
  BeamMultiSelectDropdownOnChangeHandler,
} from './BeamDropdown.types'

export interface BEAM_DROPDOWN_OPTION {
  label: string
  value: string
  disabled?: boolean
  disabledCopy?: string
}

interface DropdownProps extends BeamDSProps {
  /**
   * An array of options
   */
  options: BEAM_DROPDOWN_OPTION[]
  /**
   * Default selection from the list of options. Values are indexes of the `options` array
   */
  defaultValue?: number
  /**
   * Label above the dropdown widget.
   */
  label?: string
  /**
   * Style of button
   */
  placeholder?: string
  /**
   * Allow for multiple options to be chosen
   */
  multiple?: boolean
  /**
   * Optional click handler
   */
  onChange?: BeamDropdownOnChangeHandler | BeamMultiSelectDropdownOnChangeHandler
  /**
   * Name of the dropdown
   */
  name?: string
  /**
   * Value displayed in dropdown
   */
  value?: string | string[]
  /**
   * Disable interaction on the dropdown
   */
  disabled?: boolean
  /**
   * ClassName for dropdown
   */
  className?: string
  /**
   * Set `hoist` if the dropdown is cut off by a parent container
   * or misaligned/detached from the input box. This makes the dropdown
   * use fixed positioning instead of absolute.
   * See https://shoelace.style/components/dropdown?id=hoisting
   */
  hoist?: boolean
  /**
   * Determines if the input is required
   */
  required?: boolean
  /**
   * Adds a clear button when the select is not empty
   */
  clearable?: boolean
  /**
   * Fills the background of the combobox
   */
  filled?: boolean
  /**
   * Grants the ability to modify slots for the component, in case you want to override certain elements
   */
  children?: React.ReactNode | null
  /**
   * Callback function invoked when dropdown is clicked. Does not modify the dropdown default onClick behavior.
   */
  onClickCallback?: () => void
}

/**
 * Primary button component
 */
export const BeamDropdown = forwardRef<SlSelectElement, DropdownProps>(function BeamDropdown(
  {
    label,
    options,
    placeholder,
    onChange,
    hoist = false,
    multiple = false,
    name = undefined,
    defaultValue,
    value = undefined,
    disabled = false,
    className,
    required = false,
    clearable = false,
    filled = false,
    children = null,
    onClickCallback,
    ...props
  },
  ref
) {
  // SlOption value cannot contain spaces, because it separates multiselect values with spaces
  // Logic below converts values to a safe format for use within the component,
  // and then converts them back in the change handler
  // https://github.com/shoelace-style/shoelace/blob/next/src/components/select/select.component.ts#L98-L108
  // https://github.com/shoelace-style/shoelace/blob/458def78308919d7ed8e4c3c718213164a79877b/src/components/option/option.component.ts#L43-L48
  const getSafeValue = camelCase

  const optionsWithSafeValue = options.map(option => {
    return {
      ...option,
      safeValue: getSafeValue(option.value),
    }
  })

  const optionsBySafeValue = optionsWithSafeValue.reduce((result, option) => {
    result[option.safeValue] = option
    return result
  }, {} as Record<string, any>)

  const privateProps: { [key: string]: any } = {}
  if (defaultValue === 0 || (defaultValue && defaultValue > 0)) {
    privateProps.value = optionsWithSafeValue[defaultValue].safeValue
  }
  if (value) {
    privateProps.value = Array.isArray(value)
      ? value.map(getSafeValue) // note: React sets the property through JS, not the attribute, so we don't need to join(' ')
      : getSafeValue(value)
  }

  return (
    <SlSelect
      {...props}
      ref={ref}
      label={label}
      name={name}
      className={cx(`beam--dropdown`, className)}
      placeholder={placeholder}
      multiple={multiple}
      clearable={clearable}
      filled={filled}
      onClick={() => {
        if (onClickCallback) {
          onClickCallback()
        }
      }}
      onSlInput={event => {
        const eventTarget = event.target as SlInputElement
        const safeValues: string | string[] =
          (typeof eventTarget.value === 'string' && eventTarget.value.split(' ')) ||
          eventTarget.value
        const selectedOptions =
          typeof safeValues === 'string'
            ? safeValues
            : safeValues.map((v: string) => optionsBySafeValue[v].value)
        // Proxy the "value" property so it returns in format we want without setting it on the underlying element
        // (format = original string or array of strings):
        const value = multiple ? selectedOptions : selectedOptions[0]
        const targetProxy = new Proxy(eventTarget, {
          get(target, prop, _receiver) {
            if (prop === 'value') return value
            return Reflect.get.apply(null, arguments as any)
          },
        })
        const eventProxy = new Proxy(event, {
          get(target, prop, _receiver) {
            if (prop === 'target' || prop === 'currentTarget') return targetProxy
            return Reflect.get.apply(null, arguments as any)
          },
        })

        // event.detail is readonly so I don't think this does anything
        event.detail.selectedOptions = selectedOptions as never // alternative to reading event.target.value through proxy

        if (onChange) {
          onChange(eventProxy as any)
        }
      }}
      disabled={disabled}
      hoist={hoist}
      required={required}
      {...privateProps}>
      {disabled && <SlIcon name="lock" slot="prefix" />}
      {optionsWithSafeValue.map(
        ({ label, safeValue, value: fullValue, disabled, disabledCopy }) => {
          return (
            <BeamTooltip
              key={safeValue}
              content={disabled && disabledCopy ? disabledCopy : ''}
              placement={'left'}
              hoist={disabled && disabledCopy ? true : false}>
              <SlOption
                key={safeValue}
                value={safeValue}
                data-value={fullValue}
                disabled={disabled}
                className={`beam--dropdown--option ${multiple ? 'multiple' : 'single'}`}>
                {disabled ? (
                  <span>
                    <div className={'w-[11px] inline-block mr-1'}>
                      <LockIcon />
                    </div>
                    {label}
                  </span>
                ) : (
                  <span>{label}</span>
                )}
                {multiple && (
                  <span slot={'prefix'} className={'beam--dropdown--option-checkbox'}>
                    <SlIcon
                      name={'check'}
                      library={'system'}
                      className={'beam--dropdown--option-checkbox-icon'}
                    />
                  </span>
                )}
              </SlOption>
            </BeamTooltip>
          )
        }
      )}
      {!!children && children}
    </SlSelect>
  )
})

import { getV2ApiUrl } from '../../../../../api/auth'
import { axiosRequest } from '../../../../../utils/axiosRequest'
import {
  CreatePromoAPIResponse,
  CreatePromoRequestBody,
  UpdateAutoBoostRequestBody,
  UpdateAutoBoostResponse,
} from '../../AutoBoostANonprofitModal.types'
import {
  APIValidateAffiliateCodeCsvRequestBody,
  APIValidateAffiliateCodeCsvResponse,
  PromoCampaignType,
} from '../promo.types'

export interface UpdateInfluencerCampaignRequestBody extends UpdateAutoBoostRequestBody {
  displayAffiliateName: boolean
  type: PromoCampaignType
}

/**
 * @throws AxiosError
 */
export async function createNewInfluencerCampaign({
  data,
  partnerId,
}: {
  data: CreatePromoRequestBody
  partnerId: number
}): Promise<CreatePromoAPIResponse> {
  const response = await axiosRequest(
    'POST',
    getV2ApiUrl(`/promotions?partnerId=${partnerId}`),
    data
  )
  return response.data
}

/**
 * @throws AxiosError
 */
export async function updateInfluencerCampaign({
  data,
  partnerId,
}: {
  data: UpdateInfluencerCampaignRequestBody
  partnerId: number
}): Promise<UpdateAutoBoostResponse> {
  const response = await axiosRequest(
    'POST',
    getV2ApiUrl(`/promotions/update?partnerId=${partnerId}`),
    data
  )
  return response.data
}

/**
 * @throws AxiosError
 */
export async function validateAffiliateCodeCsv(
  data: APIValidateAffiliateCodeCsvRequestBody
): Promise<APIValidateAffiliateCodeCsvResponse> {
  const response = await axiosRequest(
    'POST',
    getV2ApiUrl(`/promotions/validate-affiliate-code-csv`),
    data
  )
  return response.data
}

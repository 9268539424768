import { createColumnHelper } from '@tanstack/react-table'
import { capitalize } from 'lodash'
import { useMemo } from 'react'

import { BeamTable } from '../../../../../stories/BeamTable'
import { FetchTransactionsResponseObject } from '../../types'
import { convertToCurrency, sanitizeTransactionData } from './helpers'

const expandedRowComponent = ({ row }: { row?: { original: FetchTransactionsResponseObject } }) => {
  const rowData = row?.original
  if (!rowData) return null

  const data = [
    {
      label: 'Order ID',
      value: rowData.orderId,
    },
    {
      label: 'Cart Total',
      value: convertToCurrency(rowData.cartTotal),
    },
    {
      label: 'Includes Subscription',
      value: rowData.includesSubscription ? 'Yes' : 'No',
    },
    {
      label: 'Store ID',
      value: rowData.storeId,
    },
    {
      label: 'Currency',
      value: rowData.currency,
    },
    {
      label: 'isTest',
      value: rowData.isTest ? 'Yes' : 'No',
    },
    {
      label: 'Chain ID',
      value: rowData.chainId,
    },
    {
      label: 'Timestamp (UTC)',
      value: new Date(rowData.timestamp).toISOString(),
    },
    {
      label: 'Nonprofit ID',
      value: rowData.nonprofitId,
    },
    {
      label: 'Status',
      value: capitalize(rowData.status),
    },
  ]

  return (
    <div className="grid grid-cols-3 gap-y-6 ">
      {data.map(item => (
        <div className="flex flex-col w-fit space-y-2" key={item.label}>
          <div className="font-black text-md">{item.label}</div>
          <div className={'text-md'}>{item.value}</div>
        </div>
      ))}
    </div>
  )
}

interface TransactionLogTableProps {
  transactions: FetchTransactionsResponseObject[]
}
export const TransactionLogTable = ({ transactions }: TransactionLogTableProps) => {
  const columnHelper = createColumnHelper<FetchTransactionsResponseObject>()

  const columns = [
    columnHelper.accessor('storeId', {
      header: 'Store ID',
    }),
    columnHelper.accessor('nonprofitId', {
      header: 'Nonprofit ID',
    }),
    columnHelper.accessor('cartTotal', {
      header: 'Cart Total',
      cell: ({ row }) => convertToCurrency(row.original.cartTotal),
    }),
    columnHelper.accessor('currency', {
      header: 'Currency',
    }),
    columnHelper.accessor('timestamp', {
      header: 'Timestamp',
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: ({ row }) => capitalize(row.original.status),
    }),
  ]

  const sanitizedTransactions = useMemo(
    () => transactions.map(sanitizeTransactionData),
    [transactions]
  )

  return (
    <BeamTable
      columns={columns}
      data={sanitizedTransactions}
      expandedRowComponent={expandedRowComponent}
      noDataMessage={'No transactions found'}
    />
  )
}

import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { getV2ApiUrl } from '../../../../../../api/auth'
import { useBeamFetch } from '../../../../../../hooks/useBeamFetch'
import { BeamLoadingIndicator } from '../../../../../../stories/BeamLoadingIndicator'
import { CenteredContent } from '../../../../../root/CenteredContent'
import { useGlobalNotifications } from '../../../../../root/context/GlobalNotificationContext'
import { GetPromoByIdResponse } from '../../promo.types'
import {
  InfluencerFormContext,
  InfluencerPromoFormModal,
} from '../components/InfluencerFormContext'
import { InfluencerCampaignForm } from '../InfluencerCampaignForm'
import { InfluencerCampaignPageLayout } from '../layout'

export const EditInfluencerCampaignPage = () => {
  const { campaignId }: { campaignId: string } = useParams()
  const [activeModal, setActiveModal] = useState<InfluencerPromoFormModal | null>(null)
  const { data, isLoading, error } = useBeamFetch<GetPromoByIdResponse>(
    getV2ApiUrl(`/promotions/${campaignId}`),
    {
      method: 'GET',
    }
  )
  const { notify } = useGlobalNotifications()
  const formId = `edit-influencer-campaign-form-${campaignId}`

  useEffect(
    function showErrorNotification() {
      if (error) {
        notify({
          variant: 'error',
          text: 'There was an error fetching the campaign data. Please try again or contact support.',
          closable: true,
          duration: Infinity,
        })
      }
    },
    [error, notify]
  )

  return (
    <InfluencerFormContext.Provider
      value={{
        formId,
        promoData: data || undefined,
        modalState: {
          activeModal,
          setActiveModal,
          handleCloseModal: () => setActiveModal(null),
        },
      }}>
      <InfluencerCampaignPageLayout>
        {isLoading ? (
          <CenteredContent>
            <BeamLoadingIndicator />
          </CenteredContent>
        ) : (
          <InfluencerCampaignForm />
        )}
      </InfluencerCampaignPageLayout>
    </InfluencerFormContext.Provider>
  )
}

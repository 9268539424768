import cx from 'classnames'

import { BeamDSProps } from '../interface'
import $$ from './beam-loading-indicator.module.css'

interface BeamLoadingIndicatorProps extends BeamDSProps {
  /**
   * Makes component absolutely positioned with a grayscale backdrop.
   * The component will take up the full width and height of its parent.
   */
  withBackdrop?: boolean
  color?: null | string // beam color (charcoal-500); set to null to use text color
}

/**
 * Loading Indicator component
 */
export const BeamLoadingIndicator = ({
  withBackdrop,
  color = 'sky-800',
  ...props
}: BeamLoadingIndicatorProps) => {
  const colorClassName = color ? `text-${color}` : null
  const loadingIndicatorBody = (
    <div
      {...props}
      className={cx(
        $$.loadingIndicator,
        'beam--loading-indicator',
        colorClassName,
        props.className
      )}>
      <div className={$$.ldsEllipsis} aria-label={'Loading'}>
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )

  return withBackdrop ? (
    <div className="fixed inset-0 z-[100] flex items-center justify-center w-full h-full">
      {/* Backdrop layer */}
      <div className="absolute inset-0 bg-sky-800 opacity-50" />

      {/* Loading indicator layer */}
      <div className="relative z-[101]">{loadingIndicatorBody}</div>
    </div>
  ) : (
    loadingIndicatorBody
  )
}

import { getV2ApiUrl } from '../../api/auth'
import { axiosRequest } from '../../utils/axiosRequest'
import { setInvoices, setLoadingInvoices } from '../actions'

export const fetchPartnerInvoices = partnerId => {
  return dispatch => {
    dispatch(setLoadingInvoices({ loading: true, error: false, message: 'Getting Invoices...' }))
    axiosRequest('get', `${getV2ApiUrl()}/invoices/partner/${partnerId}`)
      .then(res => {
        dispatch(setInvoices(res.data))
        dispatch(setLoadingInvoices({ loading: false, error: false }))
      })
      .catch(error => {
        dispatch(
          setLoadingInvoices({ loading: false, error, message: 'Failed to retrieve invoices' })
        )
      })
  }
}

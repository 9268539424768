import { useEffect, useState } from 'react'
import { Prompt } from 'react-router-dom'

/**
 * React hook that triggers a warning when the user attempts to leave the page
 * and there are unsaved changes.
 *
 * ### Example
 * ```typescript jsx
 *   const { setIsDirty, PromptComponent } = useFormCloseWarning();
 *   const handleInputChange = (event) => {
 *     setInputValue(event.target.value);
 *     setIsDirty(true); // Mark the form as dirty when the user modifies an input
 *   };
 *
 *   return (
 *    <div>
 *      {PromptComponent}
 *      ...other code
 *    </div>
 *   )
 *
 * ```
 */
export function useFormCloseWarning(
  warningMessage = 'You have unsaved changes. Are you sure you want to leave?'
) {
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isDirty) {
        event.preventDefault()
        event.returnValue = warningMessage
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isDirty, warningMessage])

  // Necessary for triggering warning when navigating user react-router
  const PromptComponent = <Prompt when={isDirty} message={warningMessage} />

  return {
    setIsDirty,
    PromptComponent,
  }
}

import { useMemo } from 'react'

import { useBeamSelector } from '../hooks'
import { TUser } from '../utils/types'

type FeatureFlagKey =
  | 'partner-uploads'
  | 'old-ach-details-message'
  | 'can-see-multi-site-features'
  | 'can-use-new-promo-ui'
  | 'nonprofits-by-store-filtering'
  | 'can-see-promos-ctas'
  | 'can-see-campaign-management-feature'
  | 'subscription-reporting'
  | 'disable-enhanced-features'
  | 'disable-subscription-reporting'
  | 'social-share-for-promos-upload'
  | 'can-see-dev-console-links'
  | 'social-share-for-nonprofits-upload'
  | 'influencer-campaign'
  | 'optimal-roi-refactor'

/**
 * @example
 *
 * const featureFlags = useFeatureFlags()
 *
 * if (featureFlags['partner-uploads']) {
 *   return <ComponentIncludingPartnerUploadsFeature />
 * }
 *
 * return <ComponentExcludingPartnerUploadsFeature />
 */
export const useFeatureFlags = () => {
  const user: TUser = useBeamSelector(({ user }) => user)

  const featureFlagKeyToStatusMap = useMemo(() => {
    const userFeatureFlags = user.featureFlags || []

    return userFeatureFlags.reduce((prev, curr) => {
      return { ...prev, [curr.key]: curr.active }
    }, {} as Record<FeatureFlagKey, boolean>)
  }, [user.featureFlags])

  return featureFlagKeyToStatusMap
}

export enum PromoCampaignType {
  Boost = 'boost',
  NonprofitSwap = 'nonprofit_swap',
  Affiliate = 'affiliate',
}

export const promoCampaignDisplayMap: Record<PromoCampaignType, string> = {
  [PromoCampaignType.Boost]: 'Boost',
  [PromoCampaignType.NonprofitSwap]: 'Swap',
  [PromoCampaignType.Affiliate]: 'Influencer',
}

// From generated schema
export interface APIPromoObject {
  id: number
  name: string
  promoText?: string | null
  chainId: number
  availableToWholeChain: boolean
  boostAllNonprofits?: boolean
  boostedStoreNonprofitIds: number[]
  startTime: string
  endTime: string | null
  pdpTextColorPrimary?: string | null
  pdpColorPrimary?: string | null
  textColorPrimary?: string | null
  colorPrimary: string | null
  foregroundColor?: string | null
  multiplier: number
  boostedNonprofits?: string
  boostStatus: string
  hasPromoAssetUrl?: boolean
  displayAffiliateName: boolean | null
  type: PromoCampaignType
}

// From API generated types
export interface GetAllPromosResponse {
  completedPromos: APIPromoObject[]
  completedPromosCount: number
  currentPromos: APIPromoObject[]
  currentPromosCount: number
}

export enum NonprofitFileEntryOperationType {
  new = 'new',
  remove = 'remove',
}
export interface NonprofitFileEntryOperation {
  type: NonprofitFileEntryOperationType
  file?: File
}
export interface NonprofitFileEntry {
  nonprofitId: number
  nonprofitName: string
  url: string | null
  operation?: NonprofitFileEntryOperation
}

export enum PromoModal {
  NoModal = 'NoModal',
  BoostANonprofit = 'BoostANonprofit',
  RequestANewNonprofit = 'RequestANewNonprofit',
  DeleteAPromoConfirmation = 'DeleteAPromoConfirmation',
  FirstTimeExperienceSocialShare = 'FirstTimeExperienceSocialShare',
  PromoSocialShareUpload = 'PromoSocialShareUpload',
  NonprofitSocialShareUpload = 'NonprofitSocialShareUpload',
}

// From API generated types
export interface APIPromoCodeGroup {
  name?: string
  promoCodes?: (
    | {
        type?: string
        attributes?: {
          value?: string
        }
      }
    | {
        type?: string
        attributes?: {
          queryParams?: {
            key: string
            value: string
          }[]
        }
      }
  )[]
}

export interface APIPromoCodeGroupsCsv {
  schema: {
    name: string
    version: string
  }
  value: string
}

// From API generated types
export interface GetPromoByIdResponse {
  id: number
  chainId: number
  /** @enum {string} */
  type: 'boost' | 'affiliate' | 'nonprofit_swap'
  name: string
  promoText: string | null
  availableToWholeChain: boolean
  boostAllNonprofits: boolean
  boostedStoreNonprofitIds: number[]
  /** Format: date-time */
  startTime: string
  /** Format: date-time */
  endTime: string | null
  multiplier: number
  config: {
    sortOrder?: {
      chainNonprofitId: string
      sortOrderIn: number
      sortOrderOut: number
    }[]
    nonprofitSwaps?: {
      storeNonprofitIn: number
      storeNonprofitOut: number
    }[]
  }
  skus: string[]
  promoCodes: string[]
  active: boolean
  pdpColorPrimary?: string | null
  pdpTextColorPrimary?: string | null
  textColorPrimary?: string | null
  colorPrimary: string | null
  foregroundColor: string | null
  displayAffiliateName: boolean | null
  promoCodeGroups: APIPromoCodeGroup[] | null
  activeNonprofits: number[]
  displayAllNonprofits: boolean
  boostAmount: number
  campaignName: string
  campaignColor: string | null
  campaignTextColor?: string | null
  campaignPdpColor?: string | null
  campaignPdpTextColor?: string | null
}

export interface DeletePromoResponse {
  id: number
  enhancedFeatures: {
    permissions: {
      campaign_action: {
        access: boolean
        countUsed: number
        countRemaining: number | 'Unlimited'
        maximum: number | 'Unlimited'
      }
    }
  }
}
export interface DeletePromoReqBody {
  promoId: number
  partnerId: number
}

export interface APIValidateAffiliateCodeCsvRequestBody {
  schema: {
    name: string
    version: string
  }
  value: string
  dateRange: {
    /**
     * Format: date-time
     * @description ISO formatted date
     * @example 2023-04-19T03:23:59.301Z
     */
    startsAt: string
    /**
     * Format: date-time
     * @description ISO formatted date
     * @example 2023-04-19T03:23:59.301Z
     */
    endsAt?: string | null
  }
}

// From API generated types
export interface APIValidateAffiliateCodeCsvResponse {
  errors: {
    message: string
  }[]
  value: {
    promoCodeGroups: APIPromoCodeGroup[] | null
  }
}

import { AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import { useCallback, useState } from 'react'

import { axiosRequest } from '../utils/axiosRequest'

interface AsyncBeamFetchOptions<T = any> extends AxiosRequestConfig {
  data?: T
}
/**
 * Generic data fetching hook that returns an async function that needs to be explicitly invoked.
 */
export function useAsyncBeamFetch<Res = unknown, Req = unknown>(
  url: string,
  options: AsyncBeamFetchOptions<Req>
) {
  const [data, setData] = useState<Res | null>(null)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  /**
   * Does NOT throw.
   */
  const fetchData = useCallback(async () => {
    setIsFetching(true)
    setError(null)

    try {
      const response = (await axiosRequest(
        options?.method as Method,
        url,
        options.data
      )) as AxiosResponse<Res>
      setData(response.data)
    } catch (err) {
      console.error(err)
      setError(err instanceof Error ? err.message : 'An unknown error occurred.')
    } finally {
      setIsFetching(false)
    }
  }, [url, options])

  return { fetchData, data, isFetching, error }
}

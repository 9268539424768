import { SlIcon } from '@shoelace-style/shoelace/dist/react'
import { delay } from 'lodash'
import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { getV2ApiUrl } from '../../../../../../api/auth'
import { useBeamSelector } from '../../../../../../hooks'
import { useAsyncBeamFetch } from '../../../../../../hooks/useAsyncBeamFetch'
import { BeamButton } from '../../../../../../stories/BeamButton'
import { BeamLoadingIndicator } from '../../../../../../stories/BeamLoadingIndicator'
import { BeamModal } from '../../../../../../stories/BeamModal'
import { TUser } from '../../../../../../utils/types'
import { useGlobalNotifications } from '../../../../../root/context/GlobalNotificationContext'
import {
  UpdateAutoBoostRequestBody,
  UpdateAutoBoostResponse,
} from '../../../AutoBoostANonprofitModal.types'
import { mapPromoDataToUpdatePromoBody } from '../helpers'
import { InfluencerFormContext } from './InfluencerFormContext'

interface EndCampaignModalProps {
  isOpen: boolean
  handleClose: () => void
}
export const EndCampaignModal = ({ isOpen = false, handleClose }: EndCampaignModalProps) => {
  const history = useHistory()
  const user: TUser = useBeamSelector(({ user }) => user)
  const { notify } = useGlobalNotifications()
  const { promoData } = useContext(InfluencerFormContext)

  const {
    fetchData: updatePromo,
    data: updatePromoResponse,
    isFetching: updatePending,
    error,
  } = useAsyncBeamFetch<UpdateAutoBoostResponse, UpdateAutoBoostRequestBody>(
    getV2ApiUrl(`/promotions/update?partnerId=${user.partnerId}`),
    {
      method: 'POST',
      data:
        promoData &&
        mapPromoDataToUpdatePromoBody({ ...promoData, endTime: new Date().toISOString() }),
    }
  )

  const handleConfirm = async () => {
    await updatePromo()
  }

  useEffect(
    function showErrorToastOnFailure() {
      if (error) {
        notify({
          variant: 'error',
          text: error,
        })
      }
    },
    [error, notify]
  )

  useEffect(function redirectOnSuccess() {
    if (updatePromoResponse) {
      notify({
        variant: 'success',
        text: 'Campaign ended successfully',
        icon: <SlIcon library={'system'} name={'checkCircle'} />,
      })
      delay(() => {
        history.push('/nonprofits/campaigns')
      }, 500)
    }
  })

  return (
    <BeamModal
      open={isOpen}
      label="Are you sure you want to end this campaign?"
      disableClose={updatePending}
      body={
        <>
          <div className={'text-center relative'}>
            {updatePending && <BeamLoadingIndicator withBackdrop />}
            <p
              className={
                'leading-normal font-secondary text-md text-charcoal-800 text-center my-4 max-w-[350px] mx-auto'
              }>
              Ending this campaign means the promo will no longer be on your site and campaign will
              be moved to “completed campaigns” in your campaign management dashboard
            </p>
          </div>
        </>
      }
      footer={
        <div slot={'footer'} className={'flex flex-col max-w-[350px] mx-auto'}>
          <BeamButton
            className={'mt-4'}
            onClick={handleClose}
            type={'button'}
            disabled={updatePending}>
            No, Don&apos;t End Campaign
          </BeamButton>
          <BeamButton
            className={'mt-4'}
            variant={'emphasis_white'}
            onClick={handleConfirm}
            type={'button'}
            disabled={updatePending}>
            <span className={'text-cherry-800'}>Yes, End Campaign</span>
          </BeamButton>
        </div>
      }
      onCloseCallback={handleClose}
    />
  )
}

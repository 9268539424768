import { SlDetails } from '@shoelace-style/shoelace/dist/react'
import cx from 'classnames'
import { ReactNode } from 'react'

import { BeamDSProps } from '../interface'
import $$ from './beam-expandable-details.module.css'

interface BeamExpandableDetailsProps extends BeamDSProps {
  /**
   * Label that shows up at the top of the expandable box.
   */
  label: string | ReactNode
  /**
   * Content for Expandable Details
   */
  children: string | React.ReactNode
}

/**
 * BeamExpandableDetails component
 */
export const BeamExpandableDetails = ({
  label,
  children,
  ...props
}: BeamExpandableDetailsProps) => {
  return (
    <SlDetails {...props} className={cx($$.beamExpandableDetails, props.className)}>
      <div slot={'summary'}>{label}</div>
      <ChevronIcon slot="expand-icon" />
      <ChevronIcon slot="collapse-icon" angle="180deg" />
      {children}
    </SlDetails>
  )
}

const ChevronIcon = ({ slot, angle = '90deg' }: { slot: string; angle?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="#0058CC"
      strokeWidth={1}
      stroke="#0058CC"
      className="bi bi-chevron-right"
      viewBox="0 0 16 16"
      // @ts-ignore
      slot={slot}
      style={{ rotate: angle }}>
      <path
        fillRule="evenodd"
        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
      />
    </svg>
  )
}

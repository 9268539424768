import { SlIcon } from '@shoelace-style/shoelace/dist/react'
import { ReactNode } from 'react'
import { useHistory } from 'react-router-dom'

import { BeamButton } from '../../../../../stories/BeamButton'
import { BeamSEO } from '../../../../root/BeamSEO'
import { PageHeaderContainer } from '../../../../root/PageHeaderContainer'
import { BeamSlIcon } from '../../../common/BeamSlIcon'
import { checkIsLivePromo, checkIsUpcomingPromo } from '../promo-helpers'
import { DeleteCampaignModal } from './components/DeleteCampaignModal'
import { EndCampaignModal } from './components/EndCampaignModal'
import {
  InfluencerPromoFormModal,
  useInfluencerFormContext,
} from './components/InfluencerFormContext'

interface InfluencerCampaignLayoutProps {
  children: ReactNode
}

export const InfluencerCampaignPageLayout = ({ children }: InfluencerCampaignLayoutProps) => {
  const history = useHistory()
  const { formId, promoData, modalState } = useInfluencerFormContext()

  const { activeModal, setActiveModal, handleCloseModal } = modalState
  const isEditMode = !!promoData

  const isUpcomingPromo = promoData && checkIsUpcomingPromo({ startTime: promoData.startTime })
  const isLivePromo =
    promoData && checkIsLivePromo({ startTime: promoData.startTime, endTime: promoData.endTime })

  const onClickEndOrDelete = (type: InfluencerPromoFormModal) => {
    if (!promoData) {
      return
    }
    setActiveModal(type)
  }

  return (
    <main>
      <EndCampaignModal
        isOpen={activeModal === InfluencerPromoFormModal.END_PROMO}
        handleClose={handleCloseModal}
      />
      <DeleteCampaignModal
        isOpen={activeModal === InfluencerPromoFormModal.DELETE_PROMO}
        handleClose={handleCloseModal}
      />

      <BeamSEO title={'Influencer Campaign'} />
      <PageHeaderContainer withBackground>
        <div className={'text-sky-800'}>
          <BeamButton
            variant={'text'}
            className={'w-min text-sky-800'}
            type={'button'}
            onClick={() => history.replace('/nonprofits/campaigns')}>
            Back to Campaign Management
            <SlIcon
              slot={'prefix'}
              library={'system'}
              name={'chevronUp'}
              style={{ width: '16px', height: '16px', rotate: '-90deg' }}
            />
          </BeamButton>
        </div>
        <div className={'flex flex-row justify-between'}>
          <h1 className={'m-0'}>Start an Influencer Campaign</h1>
          <div className={'flex flex-row gap-2'}>
            {isEditMode && isLivePromo && (
              <BeamButton
                variant={'white'}
                className={'max-w-[210px]'}
                type={'button'}
                onClick={() => onClickEndOrDelete(InfluencerPromoFormModal.END_PROMO)}
                disabled={isUpcomingPromo}>
                End Campaign
              </BeamButton>
            )}
            <BeamButton variant={'basic'} className={'max-w-[210px]'} type={'submit'} form={formId}>
              {isEditMode ? 'Update' : 'Start'} Campaign
            </BeamButton>
          </div>
        </div>
      </PageHeaderContainer>

      <div className={'pt-6 w-full desktop:w-3/4'}>{children}</div>

      {isUpcomingPromo && (
        <div className={'flex justify-start mt-8'}>
          <BeamButton
            type={'button'}
            variant={'text'}
            className={'text-cherry-800 w-min text-sm'}
            onClick={() => onClickEndOrDelete(InfluencerPromoFormModal.DELETE_PROMO)}>
            <BeamSlIcon
              name={'trash'}
              slot={'prefix'}
              library={'system'}
              style={{ width: '24px', height: '24px' }}
            />
            Delete this campaign
          </BeamButton>
        </div>
      )}
    </main>
  )
}

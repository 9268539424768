import { axiosRequest } from '../utils/axiosRequest'
import { API_BASE_PATH_PORTAL } from './auth'

export interface APIStripeDataResponse {
  stripeCustomerId?: string
  autopay?: boolean
}

export async function fetchStripeData(chainId: number): Promise<APIStripeDataResponse> {
  const data = await axiosRequest(
    'get',
    `${API_BASE_PATH_PORTAL}/v2/invoices/${chainId}/stripeData`
  )

  return data?.data
}

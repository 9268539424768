import cx from 'classnames'
import { useCallback, useMemo, useRef, useState } from 'react'
import { FileWithPath, useDropzone } from 'react-dropzone'

import { BeamSlIcon } from '../../components/partner/common/BeamSlIcon'
import { BeamSlIconButton } from '../../components/partner/common/BeamSlIconButton/BeamSlIconButton'
import { BeamProgressBarV2 } from '../BeamProgressBar/BeamProgressBarV2'
import $$ from './beam-file-dropzone.module.css'
import {
  AcceptedFileType,
  FileStatus,
  FileStatusLabel,
  FileWithDisplayName,
} from './beam-file-dropzone-types'
import { convertToFileWithDisplayName, formatAcceptProp } from './beamFileDropzoneHelpers'

interface BeamDropzoneProps {
  /**
   * Text shown in the dropzone before uploading a file.
   */
  placeholderText?: string
  /**
   * The file types the dropzone should allow.
   */
  accept?: AcceptedFileType[]
  /**
   * Props set on the input that holds the files.
   * Required in order to access the files from the ref using `ref.current.files`.
   */
  inputProps?: {
    name?: string
  }
  /**
   * Callback function to handle the upload request for a dropped file.
   * Invoked for every valid file added to the dropzone, allowing the parent component to perform the upload and update the file's status accordingly.
   *
   * Important: Callback functions passed as props must always use `useCallback`
   *
   * @param file - The file to be uploaded.
   * @param updateStatus - Updates the file's status and optionally sets error messages.
   */
  handleFileUploadCallback: (
    file: File,
    updateStatus: (status: FileStatusLabel, errors?: string[]) => void
  ) => void
  onFileRemoveCallback: (newFileList: File[]) => void
  fileUploadsState: {
    fileUploads: FileWithDisplayName[]
    setFileUploads: (files: FileWithDisplayName[]) => void
  }
}
interface FileProgress {
  [fileName: string]: {
    usesFakeProgress: boolean
    fakeProgressComplete: boolean
  }
}

export const BeamFileDropzone = ({
  placeholderText,
  accept,
  inputProps,
  handleFileUploadCallback,
  onFileRemoveCallback,
  fileUploadsState,
}: BeamDropzoneProps) => {
  const [fileStatuses, setFileStatuses] = useState<FileStatus>({})
  const [fileProgress, setFileProgress] = useState<FileProgress>({})
  const [errors, setErrors] = useState<string[]>([])

  const hiddenInputRef = useRef<HTMLInputElement>(null)
  const formattedAccept = useMemo(() => formatAcceptProp(accept), [accept])

  const onDropAccepted = useCallback(
    (incomingFiles: FileWithPath[]) => {
      setErrors([])

      const newFiles = incomingFiles.map(convertToFileWithDisplayName)

      fileUploadsState.setFileUploads(newFiles)

      if (hiddenInputRef.current) {
        // Save the files on a hidden input (for form usage)
        const dataTransfer = new DataTransfer()
        incomingFiles.forEach(v => {
          dataTransfer.items.add(v)
        })
        hiddenInputRef.current.files = dataTransfer.files
      }

      for (const { file } of newFiles) {
        // Set each file's status to loading as soon as it is dropped
        setFileStatuses(prevStatuses => ({
          ...prevStatuses,
          [file.name]: 'loading',
        }))
        setFileProgress(prev => ({
          ...prev,
          [file.name]: { usesFakeProgress: true, fakeProgressComplete: false },
        }))

        // Notify the parent to handle the upload
        handleFileUploadCallback(file, function updateStatus(status, errorDetails) {
          setFileStatuses(prevStatuses => ({
            ...prevStatuses,
            [file.name]: status,
          }))
          if (errorDetails) {
            setErrors(errorDetails)
          }

          if (status === 'completed') {
            setFileProgress(prev => ({
              ...prev,
              [file.name]: { usesFakeProgress: true, fakeProgressComplete: true },
            }))
          }
        })
      }
    },
    [fileUploadsState, handleFileUploadCallback]
  )

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: formattedAccept,
    onDropAccepted,
  })

  const handleRemoveFile = useCallback(
    (fileToRemove: FileWithPath) => {
      if (!hiddenInputRef.current) {
        return
      }

      setErrors([])

      const newFiles = new DataTransfer()
      const files = Array.from(hiddenInputRef.current.files || [])
      for (const storedFile of files || []) {
        if ((storedFile as FileWithPath).name !== fileToRemove.name) {
          newFiles.items.add(storedFile)
        }
      }

      const newFileList = Array.from(newFiles.files)

      // side effects
      hiddenInputRef.current.files = newFiles.files
      fileUploadsState.setFileUploads(newFileList.map(convertToFileWithDisplayName))
      onFileRemoveCallback(newFileList)
    },
    [fileUploadsState, onFileRemoveCallback]
  )

  return (
    <section>
      <div
        {...getRootProps({
          className: cx($$.beamDropzone, {
            [$$.dragAccept]: isDragAccept,
            [$$.dragReject]: isDragReject,
            [$$.hideDropzone]: fileUploadsState.fileUploads.length > 0,
          }),
        })}>
        <input type="file" name={inputProps?.name} className={'hidden'} ref={hiddenInputRef} />
        <input {...getInputProps()} />

        <div className={cx($$.placeholderContentWrapper)}>
          <BeamSlIcon
            library={'system'}
            name={isDragReject ? 'warningCircle' : 'uploadIcon'}
            style={{ width: '56px', height: '64px' }}
          />
          <p className={$$.placeholderText}>
            {isDragReject ? (
              <>Invalid file type. Only {accept?.join(', ')} files are allowed.</>
            ) : (
              <>{placeholderText || 'Drop files here, or click to select files'}</>
            )}
          </p>
        </div>
      </div>

      <aside>
        <ul>
          {fileUploadsState.fileUploads.map(({ file, displayName }, index) => {
            return (
              <li
                key={file.path + '-' + index}
                className={cx($$.filePill, {
                  [$$.fileError]: errors.length > 0,
                })}>
                <BeamSlIcon name={'documentReport'} style={{ width: '24px', height: '24px' }} />
                <div className={'ml-1 flex flex-col w-full pr-2'}>
                  <span>{displayName}</span>

                  {fileStatuses[file.name] === 'loading' && (
                    <BeamProgressBarV2
                      progress={0}
                      showPercentage={true}
                      fakeProgressProps={fileProgress[file.name]}
                    />
                  )}
                </div>

                <BeamSlIconButton
                  className={$$.fileRemoveButton}
                  name={'trash'}
                  disabled={fileStatuses[file.name] === 'loading'}
                  onClick={() => handleRemoveFile(file)}
                />
              </li>
            )
          })}
        </ul>
        <ul className={$$.errorList}>
          {errors.length > 0 && (
            <>
              Errors:
              {errors.map(error => {
                return <li key={error}>{error}</li>
              })}
            </>
          )}
        </ul>
      </aside>
    </section>
  )
}

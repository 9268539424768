/**
 *
 * @param numerator {number | null} - Numerator. It is assumed that this will always be a decimal number (< 1).
 * @param [denominator = undefined] {number | null | undefined} - Denominator.
 * @param [decimalDigitsMax = 1] {number | null | undefined} - The number of decimal places to display.
 * @param [exact = false] {boolean | undefined} - show percentages higher than 99% or lower than 1%.
 *    (e.g. with exact=true 0.005 --> 0.5%)
 *    (e.g. with exact=false 0.005 --> <1% )
 * @param {boolean | undefined} [forceTrailingZero = false] - Forces a trailing zero after the decimal, even for smaller numbers
 * @returns {string}
 */
const percentFormat = (
  numerator,
  denominator,
  decimalDigitsMax = 1,
  exact = false,
  forceTrailingZero = false
) => {
  if (numerator === null) return '-'

  let percentage = numerator * 100

  if (denominator !== undefined && denominator !== null && denominator !== 0) {
    percentage = (numerator / denominator) * 100
  }

  if (isNaN(percentage) || percentage >= Infinity) return '-'
  if (percentage < 1 && !exact) return '<1%'
  if (percentage > 99 && !exact) return '>99%'

  // if the number is single digit add a decimal place, unless the decimal place has no value.
  if (percentage < 10 && !forceTrailingZero) {
    const hasTrailingDecimal = percentage % 1 > 0
    decimalDigitsMax = hasTrailingDecimal && !exact ? 1 : 0
  }

  // show 4 decimal places when percentage number is very small
  if (exact && percentage < 0.01) {
    decimalDigitsMax = 4
  }

  return `${percentage.toFixed(decimalDigitsMax || 0)}%`
}

/**
 * Returns the given value in dollar format. Invalid values return 'N/A'
 * @param value {number | null | undefined}
 * @param [decimalPlaces=0] {number | undefined} Number of decimal places to show. None by default.
 * Does NOT round up whole numbers.
 * @returns {string}
 *
 * @example
 * dollarFormat(100.558) // --> '$100'
 * dollarFormat(100.558, 2) // --> '$100.56'
 */
const dollarFormat = (value, decimalPlaces = 0) => {
  if ((!value && value !== 0) || typeof value !== 'number') return 'N/A'

  // avoid showing 0 when there's a non-zero value
  if (decimalPlaces === 0 && ((value < 1 && value > 0) || (value > -1 && value < 0))) {
    return value.toLocaleString('en-us', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
    })
  }

  return value.toLocaleString('en-us', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: decimalPlaces,
  })
}

export { percentFormat, dollarFormat }

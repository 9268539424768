import { combineReducers } from '@reduxjs/toolkit'

import {
  accessToken,
  displayListOfSites,
  invoices,
  loadingStates,
  site,
  sites,
  user,
} from './reducers'

export const rootReducer = combineReducers({
  accessToken,
  invoices,
  loadingStates,
  site,
  sites,
  displayListOfSites,
  user,
})

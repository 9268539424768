import { isNil } from 'lodash'
import { Dispatch } from 'redux'

import { getV2ApiUrl } from '../../api/auth'
import { axiosRequest } from '../../utils/axiosRequest'
import { TSite } from '../../utils/types'
import { setDisplayListOfSites, setLoadingSites, setSite, setSites } from '../actions'

interface FetchSiteResponse {
  shouldDisplayFilters: boolean
  stores: TSite[]
}

export function fetchSites({ chainId }: { chainId: number }) {
  return async (dispatch: Dispatch) => {
    if (isNil(chainId)) return

    try {
      dispatch(setLoadingSites({ loading: { getting: true }, error: null }))

      const response = await axiosRequest('get', `${getV2ApiUrl()}/chains/${chainId}/storeFilters`)
      const data: FetchSiteResponse = response.data

      dispatch(setSites(data.stores))
      dispatch(setSite(data.stores[0]))
      dispatch(setDisplayListOfSites(!!data.shouldDisplayFilters))
      dispatch(setLoadingSites({ loading: { getting: false }, error: null }))
    } catch (error: any) {
      dispatch(setLoadingSites({ loading: { getting: false }, error }))
    }
  }
}

import cx from 'classnames'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useBeamSelector } from '../../../hooks'
import { BeamToast } from '../../../stories/BeamToast'
import { PartnerUploadBody, TUser } from '../../../utils/types'
import { BeamSEO } from '../../root/BeamSEO'
import { PartnerUploadsTable } from '../../root/PartnerUploadsTable/PartnerUploadsTable'
import { BeamReportsPageNavigation } from '../ReportsPageBeta/BeamReportsPageNavigation'
import { fetchPartnerUploads } from './PartnerUploadsPage.api'

export const PartnerUploadsPage = () => {
  const user = useBeamSelector(({ user }) => user) as TUser
  const [partnerUploadsData, setPartnerUploadsData] = useState<PartnerUploadBody[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [fetchError, setFetchError] = useState<string | null>(null)

  useEffect(() => {
    ;(async () => {
      setFetchError(null)
      if (!user?.chainId) {
        return
      }

      try {
        const data = await fetchPartnerUploads(user.chainId)
        setPartnerUploadsData(data)
        setLoading(false)
      } catch (error: any) {
        console.error(error)
        setFetchError(error.message)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <BeamSEO title={`Custom Reports`} />
      <main className={'beam--page-content--no-gutter'}>
        <BeamToast
          open={!!fetchError}
          text={fetchError || ''}
          variant={'error'}
          onClose={() => setFetchError(null)}
        />

        <div className={'bg-charcoal-50 flex justify-between items-center px-5'}>
          <BeamReportsPageNavigation />
        </div>
        <div className={'px-6 py-4'}>
          <h2 className="beam--heading--2 !mb-1">Custom Reports</h2>
          <p className={cx('mb-6 text-md')}>
            Here you’ll find all reports uploaded by your Client Strategy Lead. Have a question?
            Check out our <Link to={''}>FAQs</Link>, or{' '}
            <Link to={`/contact-support`}>contact support.</Link>
          </p>
          <PartnerUploadsTable data={partnerUploadsData} loading={loading} />
        </div>
      </main>
    </>
  )
}

import { createColumnHelper } from '@tanstack/react-table'
import cx from 'classnames'
import { orderBy } from 'lodash'

import { BeamButton } from '../../../stories/BeamButton'
import { BeamTable } from '../../../stories/BeamTable'
import { PartnerUploadBody } from '../../../utils/types'
import { handleClickDownloadPartnerUpload } from '../../partner/PartnerUploads/PartnerUploadsPage.helpers'
import { useGtagWithContext } from '../BeamGoogleAnalytics/googleAnalyticsHelpers'
import $$ from './partner-uploads-table.module.css'
import { PartnerUploadsTableLoadingOverlay } from './PartnerUploadsTableLoadingOverlay'

interface PartnerUploadsTableProps {
  data: PartnerUploadBody[]
  /**
   * Loading state for the table
   */
  loading: boolean
}

export const PartnerUploadsTable = (props: PartnerUploadsTableProps) => {
  const { data, loading = false } = props
  const gtag = useGtagWithContext()
  const columnHelper = createColumnHelper<PartnerUploadBody>()
  const sortedPartnerUploads = orderBy(data, ['createdAt'], ['desc'])

  const columns = [
    columnHelper.accessor('fileName', {
      header: 'Document Name',
    }),
    columnHelper.display({
      id: 'download',
      header: 'Download',
      enableSorting: false,
      cell: ({ row }) => {
        const rowData = row.original
        return (
          <div>
            <BeamButton
              label={'Download'}
              variant={'text'}
              type={'button'}
              className={$$.tableButton}
              onClick={() => {
                gtag('Custom Reports | Clicked Download Custom Report', {
                  fileName: rowData.fileName,
                  chainId: rowData.chainId,
                })

                handleClickDownloadPartnerUpload({
                  chainId: rowData.chainId,
                  partnerUploadId: rowData.id,
                  fileName: rowData.fileName,
                })
              }}
            />
          </div>
        )
      },
    }),
  ]

  return (
    <div className={'relative'} aria-live={'polite'}>
      <PartnerUploadsTableLoadingOverlay loading={loading} />

      <BeamTable
        className={cx($$.partnerUploadsTable)}
        columns={columns}
        data={sortedPartnerUploads}
        condensed={true}
        noDataMessage={'No reports uploaded to Partner Portal yet'}
        enableSorting
      />
    </div>
  )
}

import { createContext, useContext } from 'react'

import { GetPromoByIdResponse } from '../../promo.types'

export enum InfluencerPromoFormModal {
  DELETE_PROMO = 'DELETE_PROMO',
  END_PROMO = 'END_PROMO',
  UPLOAD_TRACKING_LINKS = 'UPLOAD_TRACKING_LINKS',
}

interface InfluencerFormContextProps {
  formId: string | undefined
  promoData: GetPromoByIdResponse | undefined
  modalState: {
    activeModal: InfluencerPromoFormModal | null
    setActiveModal: (modal: InfluencerPromoFormModal | null) => void
    handleCloseModal: () => void
  }
}

export const InfluencerFormContext = createContext<InfluencerFormContextProps>({
  formId: undefined,
  promoData: undefined,
  modalState: {
    activeModal: null,
    setActiveModal: () => null,
    handleCloseModal: () => null,
  },
})

export function useInfluencerFormContext() {
  const context = useContext(InfluencerFormContext)
  return context
}

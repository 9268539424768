import {
  SET_ACCESS_TOKEN,
  SET_DISPLAY_LIST_OF_SITES,
  SET_INVOICES,
  SET_LOADING_SITES,
  SET_LOADING_USER,
  SET_SITE,
  SET_SITES,
  SET_USER,
} from './constants'

export const accessToken = (state = '', action) => {
  switch (action.type) {
    case SET_ACCESS_TOKEN:
      return action.value
    default:
      return state
  }
}

export const user = (state = {}, action) => {
  switch (action.type) {
    case SET_USER:
      return action.user
    default:
      return state
  }
}

/**
 * @param {TSite|null} state - The new site
 * @param {Object} action - Action type.
 *  @param {string} action.type - Action type.
 *  @param {TSite|null} action.site - The new site
 */
export const site = (
  state = {
    name: '',
    storeId: null,
  },
  action
) => {
  switch (action.type) {
    case SET_SITE:
      return action.site
    default:
      return state
  }
}

export const sites = (state = {}, action) => {
  switch (action.type) {
    case SET_SITES:
      return action.sites || []
    default:
      return state || []
  }
}

export const displayListOfSites = (state = false, action) => {
  switch (action.type) {
    case SET_DISPLAY_LIST_OF_SITES:
      return !!action.value
    default:
      return state
  }
}

export const loadingStates = (state = {}, action) => {
  switch (action.type) {
    case SET_LOADING_USER:
      return { ...state, user: action.value }
    case SET_LOADING_SITES:
      return { ...state, sites: { ...state.sites, ...action.value } }
    default:
      return state
  }
}

export const invoices = (state = [], action) => {
  switch (action.type) {
    case SET_INVOICES:
      return action.invoices
    default:
      return state
  }
}

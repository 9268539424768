import type SlDialogElement from '@shoelace-style/shoelace/dist/components/dialog/dialog'
import { SlDialog } from '@shoelace-style/shoelace/dist/react'
import { setDefaultAnimation } from '@shoelace-style/shoelace/dist/utilities/animation-registry.js'
import cx from 'classnames'

import { BeamDSProps } from '../interface'
import $$ from './beam-modal.module.css'

setDefaultAnimation('dialog.show', {
  keyframes: [
    { transform: 'scale(0.5)', opacity: '0' },
    { transform: 'scale(1)', opacity: '1' },
  ],
  options: {
    duration: 200,
  },
})

interface BeamModalProps extends BeamDSProps {
  /**
   * Title of modal
   */
  label?: string
  /**
   * Optional label override with HTML. Useful for customizing the label styles and adding additional elements like icons.
   * `label` prop must be empty/absent for this to work.
   */
  htmlLabel?: React.ReactNode
  /**
   * Is modal open
   */
  open: boolean
  /**
   * Subheading of modal
   */
  subheading?: string | React.ReactNode
  /**
   * Body of modal
   */
  body?: string | React.ReactNode
  /**
   * Footer for modal
   * Element must have 'footer' shoelace slot
   */
  footer?: React.ReactNode
  /**
   * Callback called after the dialog closes and all animations are complete.
   */
  onCloseCallback?: (e?: Event) => void
  /**
   * Prevents modal from closing if true. Useful for when data is being submitted to prevent accidentally closing.
   */
  disableClose?: boolean
  /**
   * Prevents modal from closing if true. Useful for when data is being submitted to prevent accidentally closing.
   */
  disableClickOutside?: boolean
  /**
   * Shows the shadow dividing the footer from the main modal content
   */
  showFooterShadow?: boolean
}

export const BeamModal = ({
  label = '',
  htmlLabel,
  open,
  subheading = undefined,
  body = undefined,
  footer = undefined,
  onCloseCallback,
  disableClose = false,
  disableClickOutside = false,
  showFooterShadow = false,
  ...props
}: BeamModalProps) => {
  function handleRequestClose(event: any) {
    // Prevent the dialog from closing when the user clicks on the overlay
    if (disableClickOutside && event?.detail?.source === 'overlay') {
      event.preventDefault()
    }

    // Prevent the dialog from closing no matter what
    if (disableClose) {
      event.preventDefault()
    }
  }

  return (
    <SlDialog
      {...props}
      open={open}
      label={label}
      className={cx(
        props.className,
        { 'beam--modal--no-title': !label, 'show-footer-shadow': showFooterShadow },
        'beam--modal'
      )}
      onSlRequestClose={handleRequestClose}
      onSlAfterHide={event => {
        // Other onSlAfterHide events inside modals bubble up and cause modal to close. For example, closing a dropdown in a modal also closes the modal. We check for tagName to prevent this.
        if ((event.target as SlDialogElement).tagName === 'SL-DIALOG' && onCloseCallback) {
          onCloseCallback()
        }
      }}>
      {!!htmlLabel && !label && <div>{htmlLabel}</div>}
      {subheading && (
        <div className={cx($$.subheading, props.className, 'beam--modal')}>{subheading}</div>
      )}
      {body && <div className={cx($$.body, props.className, 'beam--modal')}>{body}</div>}
      {footer}
    </SlDialog>
  )
}

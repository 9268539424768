export const SET_INVOICES = 'SET_INVOICES'
export const SET_LOADING_INVOICES = 'SET_LOADING_INVOICES'
export const SET_LOADING_SITES = 'SET_LOADING_SITES'
export const SET_LOADING_USER = 'SET_LOADING_USER'
export const SET_SITE = 'SET_SITE'
export const SET_SITES = 'SET_SITES'
export const SET_DISPLAY_LIST_OF_SITES = 'SET_DISPLAY_LIST_OF_SITES'
export const SET_USER = 'SET_USER'
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const SET_LOADING_SUPPORT_EMAIL = 'SET_LOADING_SUPPORT_EMAIL'

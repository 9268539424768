import cx from 'classnames'
import { ChangeEvent, FormEventHandler, useCallback, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useBeamSelector } from '../../../../../hooks'
import { useFormCloseWarning } from '../../../../../hooks/useFormCloseWarning'
import { BeamDropdown } from '../../../../../stories/BeamDropdown'
import { FileWithDisplayName } from '../../../../../stories/BeamDropzone/beam-file-dropzone-types'
import { BeamLoadingIndicator } from '../../../../../stories/BeamLoadingIndicator'
import { BeamTextfield } from '../../../../../stories/BeamTextfield'
import { BeamTextfieldProps } from '../../../../../stories/BeamTextfield/BeamTextfield'
import { BeamToggle } from '../../../../../stories/BeamToggle'
import { TUser } from '../../../../../utils/types'
import { useGlobalNotifications } from '../../../../root/context/GlobalNotificationContext'
import { SelectNonprofitWidgetPreview } from '../../../../root/SelectNonprofitWidgetPreview'
import { BeamSlIcon } from '../../../common/BeamSlIcon'
import { BeamSlIconButton } from '../../../common/BeamSlIconButton/BeamSlIconButton'
import {
  boostAmountDropdownOptions,
  getDefaultBoostStartDate,
  getTomorrowDate,
  MAX_CHARACTERS_CAMPAIGN_NAME,
} from '../../AutoBoostANonprofitModal'
import { checkIsLivePromo } from '../promo-helpers'
import {
  createNewInfluencerCampaign,
  updateInfluencerCampaign,
  UpdateInfluencerCampaignRequestBody,
} from './api'
import { InfluenceLinkUploadButton } from './components'
import { CsvUploadModal } from './components/CsvUploadModal'
import { InfluencerFormContext, InfluencerPromoFormModal } from './components/InfluencerFormContext'
import { mapInfluencerFormDataToPromoCreationRequestBody } from './helpers'
import $$ from './influencer-campaign-form.module.css'
import { mapPromoDataToInfluencerFormData, useNewInfluencerCampaignReducer } from './new/data-store'
import { useFetchChainNonprofitOptions } from './useFetchChainNonprofitOptions'

interface CustomTextfieldInputProps extends BeamTextfieldProps {
  supportingText?: string
}

// Extends BeamTextfield but uses a fake label in order to add subtext under the label. Shoelace doesn't offer an easy way to do this.
const CustomTextfieldInput = ({ label, supportingText, ...rest }: CustomTextfieldInputProps) => {
  const inputId = `${rest.name}-${label}`
  return (
    <div>
      <label
        htmlFor={inputId}
        className={cx($$.formInputLabel, `after:content-['*'] after:text-cherry-600`)}>
        {label}
      </label>
      <span className={'field-label-subtext block mb-2'}>{supportingText}</span>
      <BeamTextfield {...rest} id={inputId} />
    </div>
  )
}

export const InfluencerCampaignForm = () => {
  const [state, dispatch] = useNewInfluencerCampaignReducer()
  const { setIsDirty, PromptComponent } = useFormCloseWarning()

  const {
    formId,
    promoData,
    modalState: { activeModal, setActiveModal, handleCloseModal },
  } = useContext(InfluencerFormContext)
  const user: TUser = useBeamSelector(state => state.user)
  const { nonprofitOptions } = useFetchChainNonprofitOptions()
  const history = useHistory()
  const { notify } = useGlobalNotifications()

  const { formData, formStatus, formError } = state
  const isEditMode = !!promoData
  const isLiveCampaign =
    !!promoData && checkIsLivePromo({ startTime: promoData.startTime, endTime: promoData.endTime })

  useEffect(
    function redirectAfterSuccess() {
      if (formStatus === 'success') {
        notify({
          variant: 'success',
          text: `Your campaign has been successfully ${isEditMode ? 'updated' : 'created'}`,
          icon: <BeamSlIcon name={'checkCircle'} library={'system'} />,
          closable: true,
        })
        history.replace('/nonprofits/campaigns')
      }
    },
    [formStatus, history, isEditMode, notify]
  )

  useEffect(
    function setInitialEditModeValuesOnMount() {
      if (promoData) {
        dispatch({
          type: 'bulk_set_form_field',
          fields: mapPromoDataToInfluencerFormData(promoData),
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, promoData?.id]
  )

  useEffect(
    function notifyOnFormError() {
      if (formError) {
        notify({
          variant: 'error',
          text: formError,
          duration: Infinity,
        })
      }
    },
    [formError, notify]
  )

  const handleInputChange = (e: ChangeEvent<any>) => {
    let newValue = e.target.value

    if (e.target.name === 'displayAffiliateName') {
      newValue = e.target.checked
    } else if (e.target.name === 'boostMultiplier') {
      newValue = Number(e.target.value)
    } else if (['campaignPillColor', 'campaignTextColor'].includes(e.target.name)) {
      const val = e.target.value
      if (val.length >= 1 && val[0] !== '#') {
        newValue = '#' + val
      }
    }

    dispatch({
      type: 'set_form_field',
      name: e.target.name,
      value: newValue,
    })

    setIsDirty(true)
  }

  const handleCsvUploadChange = useCallback(
    (newValue: FileWithDisplayName | null) => {
      dispatch({
        type: 'set_csv_upload_value',
        value: newValue,
      })
    },
    [dispatch]
  )

  const handleSubmit: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()

    dispatch({
      type: 'set_form_status',
      value: 'submitting',
    })
    ;(async () => {
      try {
        const reqBody = await mapInfluencerFormDataToPromoCreationRequestBody({
          formData,
          chainId: user.chainId,
        })

        if (isEditMode) {
          const updateReqBody: UpdateInfluencerCampaignRequestBody = {
            ...reqBody,
            id: Number(promoData?.id),
          } as any // TODO: Remove this any once the /update endpoint is updated to accept the new fields
          await updateInfluencerCampaign({ data: updateReqBody, partnerId: user.partnerId })
        } else {
          await createNewInfluencerCampaign({ data: reqBody, partnerId: user.partnerId })
        }

        setIsDirty(false) // prevent warning from triggering when form succeeds
        dispatch({
          type: 'set_form_status',
          value: 'success',
        })
      } catch (error: any) {
        console.error(error)
        dispatch({
          type: 'set_form_error',
          error: 'An error occurred while creating the campaign. Please try again later.',
        })
        dispatch({
          type: 'set_form_status',
          value: 'idle',
        })
      }
    })()
  }

  return (
    <>
      <CsvUploadModal
        isOpen={activeModal === InfluencerPromoFormModal.UPLOAD_TRACKING_LINKS}
        handleClose={handleCloseModal}
        onCsvUploadChange={handleCsvUploadChange}
        formData={formData}
      />

      <form id={formId} onSubmit={handleSubmit}>
        {PromptComponent}

        {formStatus === 'submitting' && <BeamLoadingIndicator withBackdrop />}

        <h2>Campaign Details</h2>

        <div className="grid grid-cols-1 desktop:grid-cols-2 gap-12">
          <div className="col-span-1">
            {/*  left section */}
            <div className="flex flex-col gap-y-6">
              <CustomTextfieldInput
                name="campaignName"
                label="Campaign Name"
                supportingText={'This can be the cause name or timeframe'}
                placeholder="e.g. “Breast Cancer Awareness”, “This Week Only!”"
                maxLength={MAX_CHARACTERS_CAMPAIGN_NAME}
                helptext={`${
                  formData.campaignName.length || 0
                } / ${MAX_CHARACTERS_CAMPAIGN_NAME} character maximum`}
                onChange={handleInputChange}
                value={formData.campaignName}
                disabled={isLiveCampaign}
                required
              />
              <BeamDropdown
                name={'nonprofitIds'}
                label={'Active Nonprofit(s)'}
                placeholder={'Select Nonprofits to Boost'}
                options={nonprofitOptions}
                value={formData.nonprofitIds}
                onChange={(e: any) => handleInputChange(e)}
                disabled={isLiveCampaign}
                multiple
                clearable
                required
              />
              <BeamDropdown
                name={'boostMultiplier'}
                label={'Boost Amount'}
                placeholder={'Select Boost Amount'}
                options={boostAmountDropdownOptions}
                value={String(formData.boostMultiplier)}
                disabled={isLiveCampaign}
                onChange={(e: any) => handleInputChange(e)}
                required
              />
            </div>
          </div>
          <div className="col-span-1">
            {/*  right section */}
            <div className={'flex flex-col gap-y-6'}>
              <div>
                <label className={$$.formInputLabel}>Boost Time Frame</label>
                <p className={'field-label-subtext mb-2'}>Timezone is in your local timezone.</p>
                <div className={cx('flex flex-row justify-between')}>
                  <div className={'w-[42%]'}>
                    <BeamTextfield
                      name={`boostStartDate`}
                      value={formData.boostStartDate || getDefaultBoostStartDate()}
                      type="datetime-local"
                      placeholder="Start Date"
                      onChange={handleInputChange}
                      includeTime={true}
                      min={getTomorrowDate()}
                      disabled={isLiveCampaign}
                      required
                    />
                  </div>
                  <div
                    className={
                      'flex items-center justify-center font-primary text- text-charcoal-500 font-black'
                    }>
                    <span>to</span>
                  </div>
                  <div className={'w-[42%]'}>
                    <BeamTextfield
                      name={`boostEndDate`}
                      value={formData.boostEndDate || undefined}
                      type="datetime-local"
                      placeholder="End Date"
                      onChange={handleInputChange}
                      includeTime={true}
                      min={formData.boostStartDate || getTomorrowDate()}
                      disabled={isLiveCampaign}
                    />
                  </div>
                </div>
              </div>

              <div>
                <label className={$$.formInputLabel} htmlFor={'displayAffiliateName'}>
                  Display Influencers Name? (optional)
                </label>
                <p
                  className={
                    'm-0 p-0 text-sm text-charcoal-500 font-secondary font-normal leading-normal'
                  }>
                  Promo will display campaign name by default or you can display Influencer’s name
                  instead
                </p>
                <BeamToggle
                  id={'displayAffiliateName'}
                  name={'displayAffiliateName'}
                  label={
                    <span className={'text-charcoal-500 text-sm font-secondary'}>
                      {formData.displayAffiliateName
                        ? 'Display influencer name'
                        : 'Do not display name'}
                    </span>
                  }
                  checked={formData.displayAffiliateName}
                  disabled={isLiveCampaign}
                  onChange={handleInputChange as any}
                />
              </div>

              {formData.fileUpload?.file ? (
                <div
                  className={
                    'flex flex-row items-center justify-between hover:bg-sky-50 rounded transition-colors ease-in-out'
                  }>
                  <span className={'text-xs font-bold text-sky-500 underline'}>
                    {formData.fileUpload.displayName}
                  </span>
                  <BeamSlIconButton
                    name={'x'}
                    onClick={() => {
                      setActiveModal(InfluencerPromoFormModal.UPLOAD_TRACKING_LINKS)
                    }}
                  />
                </div>
              ) : (
                <InfluenceLinkUploadButton
                  disabled={isLiveCampaign || (isEditMode && !formData.boostStartDate)}
                  onClick={() => setActiveModal(InfluencerPromoFormModal.UPLOAD_TRACKING_LINKS)}
                />
              )}
            </div>
          </div>
        </div>

        <hr className={'mt-6 mb-8'} />

        <h2>In Cart Customizations</h2>
        <div className="grid grid-cols-1 desktop:grid-cols-2 gap-12">
          <div className="col-span-1">
            {/*  left section */}
            <CustomTextfieldInput
              name="campaignPillColor"
              label="Campaign Pill Color"
              supportingText={'Tip: Use eye catching WCAG accessible high contrast color'}
              placeholder="Hex Code #"
              onChange={handleInputChange}
              value={formData.campaignPillColor}
            />
            <BeamTextfield
              name="campaignTextColor"
              label="Campaign Text Color"
              placeholder="Hex Code #"
              onChange={handleInputChange}
              value={formData.campaignTextColor}
            />
          </div>
          <div className="col-span-1">
            {/*  right section */}
            <SelectNonprofitWidgetPreview
              promoText={`${formData.boostMultiplier}x donations${
                formData.displayAffiliateName ? ' via [Influencer name]' : ''
              }`}
              campaignName={formData.campaignName}
              multiplier={formData.boostMultiplier}
              foregroundColor={formData.campaignTextColor}
              colorPrimary={formData.campaignPillColor}
            />
          </div>
        </div>
      </form>
    </>
  )
}
